import { QrCode } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { Card, CardTitle } from '@/components/shared/shadcn-ui/card';

export const TrackIdleCard = () => {
  const { t } = useTranslation();
  return (
    <Card className="p-4">
      <QrCode className="h-16 w-16 mb-4 xl:mb-8 xl:h-24 xl:w-24" />
      <CardTitle className="xl:text-3xl">{t('track.idle.title')}</CardTitle>
      <div className="mt-4 space-y-2 xl:space-y-4">
        <p className="text-sm xl:text-xl">{t('track.idle.description')}</p>
        <p className="text-sm xl:text-xl">{t('track.idle.user-directive')}</p>
      </div>
    </Card>
  );
};
