import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import { Input } from '@/components/shared/shadcn-ui/input';
import { Label } from '@/components/shared/shadcn-ui/label';
import { useChangedObject } from '@/hooks/use-changed-object';
import { isNotEmpty } from '@/utils/helpers';
import { useAuthUserCompany, ValidationError } from '@durma-soft/gros-sdk';
import { useEditCompanyTrackLocatioMutation } from '@durma-soft/gros-sdk/dist/hooks/mutations';
import { TrackCompanyLocation } from '@durma-soft/gros-sdk/dist/types/company';
import { FormEvent, useId, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

type TrackCompanyLocationWithoutLocations = Omit<
  TrackCompanyLocation,
  'company_locations_count'
>;

interface EditCompanyTrackLocationForm {
  trackLocation: TrackCompanyLocationWithoutLocations;
  closeModal: () => unknown;
}

interface FormState {
  name: string;
  street: string | null;
  city: string | null;
  zip: string | null;
  state: string | null;
  country: string | null;
}

export const EditCompanyTrackLocationForm = ({
  trackLocation,
  closeModal,
}: EditCompanyTrackLocationForm) => {
  const uid = useId();
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormState>({
    name: trackLocation.name,
    street: trackLocation.street,
    city: trackLocation.city,
    zip: trackLocation.zip,
    state: trackLocation.state,
    country: trackLocation.country,
  });

  const company = useAuthUserCompany();

  const editCompanyTrackLocation = useEditCompanyTrackLocatioMutation();

  const [isChanged, changedObj] = useChangedObject(formData, trackLocation);

  const isValid = useMemo(() => {
    return isNotEmpty(formData.name) && isChanged;
  }, [formData, isChanged]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isChanged) return;
    try {
      await editCompanyTrackLocation.mutateAsync({
        company_id: company.id,
        track_location_id: trackLocation.id,
        ...changedObj,
      });
      closeModal();
      toast.success(t('company.track-location.edit-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('company.track-location.edit-error-msg'));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid gap-4">
        {/* Name */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-name'}>{t('common.name')}</Label>
          <Input
            required
            id={uid + '-name'}
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            placeholder={t('common.name-placeholder')}
          />
        </div>
        {/* Street */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-street'}>
            {t('common.address-details.street')}
          </Label>
          <Input
            id={uid + '-street'}
            value={formData.street || ''}
            onChange={(e) =>
              setFormData({ ...formData, street: e.target.value })
            }
            placeholder={t('common.address-details.street-placeholder')}
          />
        </div>
        {/* City */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-city'}>
            {t('common.address-details.city')}
          </Label>
          <Input
            id={uid + '-city'}
            value={formData.city || ''}
            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
            placeholder={t('common.address-details.city-placeholder')}
          />
        </div>
        {/* ZIP */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-zip'}>
            {t('common.address-details.zip')}
          </Label>
          <Input
            id={uid + '-zip'}
            value={formData.zip || ''}
            onChange={(e) => setFormData({ ...formData, zip: e.target.value })}
            placeholder={t('common.address-details.zip-placeholder')}
          />
        </div>
        {/* State */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-state'}>
            {t('common.address-details.state')}
          </Label>
          <Input
            id={uid + '-state'}
            value={formData.state || ''}
            onChange={(e) =>
              setFormData({ ...formData, state: e.target.value })
            }
            placeholder={t('common.address-details.state-placeholder')}
          />
        </div>
        {/* Country */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-country'}>
            {t('common.address-details.country')}
          </Label>
          <Input
            id={uid + '-country'}
            value={formData.country || ''}
            onChange={(e) =>
              setFormData({ ...formData, country: e.target.value })
            }
            placeholder={t('common.address-details.country-placeholder')}
          />
        </div>
      </div>
      <DialogFooter className="mt-2">
        <Button
          type="submit"
          disabled={!isValid}
          isLoading={editCompanyTrackLocation.isPending}
        >
          {t('common.save-edit')}
        </Button>
      </DialogFooter>
    </form>
  );
};
