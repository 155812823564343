/* eslint-disable react-hooks/rules-of-hooks */
import { toast } from 'sonner';
import { Key, Pencil } from 'lucide-react';
import { ColumnDef } from '@tanstack/react-table';
import {
  parsePhoneNumber,
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
  useGetChildRestaurantOwnerTokenMutation,
} from '@durma-soft/gros-sdk';
import { Restaurant } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { Link } from '@/components/shared/link';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/shared/shadcn-ui/tooltip';

import { TType } from '@/types/general';

import {
  LOCAL_STORAGE_MASTER_RESTAURANT_TOKEN_KEY,
  LOCAL_STORAGE_TOKEN_KEY,
} from '../global/storage';

export const columns = (t: TType): ColumnDef<Restaurant>[] => [
  {
    accessorKey: 'name',
    header: t('common.name'),
  },
  {
    header: t('common.address'),
    cell: (cellData) => {
      const { street, city, state, zip, country } = cellData.row.original;
      return (
        <ul className="flex flex-col gap-2">
          {/* Street */}
          {street && (
            <li className="flex gap-1">
              <span className="font-medium">
                {t('common.address-details.street')}:
              </span>
              <span>{street}</span>
            </li>
          )}
          {/* City */}
          {city && (
            <li className="flex gap-1">
              <span className="font-medium">
                {t('common.address-details.city')}:
              </span>
              <span>{city}</span>
            </li>
          )}
          {/* State */}
          {state && (
            <li className="flex gap-1">
              <span className="font-medium">
                {t('common.address-details.state')}:
              </span>
              <span>{state}</span>
            </li>
          )}

          {/* ZIP */}
          {zip && (
            <li className="flex gap-1">
              <span className="font-medium">
                {t('common.address-details.zip')}:
              </span>
              <span>{zip}</span>
            </li>
          )}
          {/* Country */}
          {country && (
            <li className="flex gap-1">
              <span className="font-medium">
                {t('common.address-details.country')}:
              </span>
              <span>{country}</span>
            </li>
          )}
        </ul>
      );
    },
  },
  {
    header: t('company.phone-number'),
    cell: (cellData) => {
      const phoneNumber =
        parsePhoneNumber(
          cellData.row.original.phone_number,
        )?.formatNational() || cellData.row.original.phone_number;

      return <div>{phoneNumber}</div>;
    },
  },
  {
    accessorKey: 'edit',
    header: '',
    cell: (cellData) => {
      const { id: restaurantId, role } = useAuthUserRestaurant();

      if (role !== RESTAURANT_USER_ROLE.OWNER) return null;

      const getChildRestaurantOwnerTokenMutation =
        useGetChildRestaurantOwnerTokenMutation();

      const handleLoginAsPartner = async () => {
        try {
          const { token } =
            await getChildRestaurantOwnerTokenMutation.mutateAsync({
              restaurant_id: restaurantId,
              child_restaurant_id: cellData.row.original.id,
            });
          localStorage.setItem(
            LOCAL_STORAGE_MASTER_RESTAURANT_TOKEN_KEY,
            localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || '',
          );
          localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
          window.location.href = '/';
        } catch (error) {
          toast.error("Couldn't login as partner");
        }
      };

      return (
        <TooltipProvider delayDuration={100}>
          <div className="flex items-center gap-5">
            <Tooltip>
              <TooltipTrigger asChild>
                <button
                  onClick={handleLoginAsPartner}
                  disabled={getChildRestaurantOwnerTokenMutation.isPending}
                  type="button"
                  className="flex items-center justify-center text-gray-700 disabled:opacity-50"
                >
                  <Key className="w-5 h-5" />
                </button>
              </TooltipTrigger>
              <TooltipContent>{t('partner.login')}</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  replace
                  to={`/restaurant/partners/edit/${cellData.row.original.id}`}
                  className="flex items-center justify-center text-gray-700"
                >
                  <Pencil className="w-5 h-5" />
                </Link>
              </TooltipTrigger>
              <TooltipContent>{t('partner.edit-details')}</TooltipContent>
            </Tooltip>
          </div>
        </TooltipProvider>
      );
    },
  },
];
