import { useEffect, useRef, useState } from 'react';

import { noop } from '@/utils/helpers';

interface UseBarcodeScannerProps {
  onScan?: (codeScanner: string) => unknown;
  enabled?: boolean;
}

export const useBarcodeScanner = ({
  onScan = noop,
  enabled = true,
}: UseBarcodeScannerProps = {}) => {
  const barcodeTimeoutRef = useRef<NodeJS.Timeout>();
  const [barcodeBuffer, setBarcodeBuffer] = useState('');

  useEffect(() => {
    if (!enabled) return;
    const handleKeyDown = (event: KeyboardEvent) => {
      const { key } = event;
      if (key === 'Enter' && barcodeBuffer.length) {
        clearTimeout(barcodeTimeoutRef.current);
        onScan(barcodeBuffer || '');
        setBarcodeBuffer('');
      } else if (key.length === 1) {
        clearTimeout(barcodeTimeoutRef.current);
        setBarcodeBuffer((prev) => prev + key);
        barcodeTimeoutRef.current = setTimeout(() => {
          setBarcodeBuffer('');
        }, 500);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [barcodeBuffer, onScan, enabled]);
};
