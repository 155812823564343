import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';

import { Modal } from '@/components/shared/modal';
import { CreateCompanyTrackLocationForm } from '@/components/company/track-locations/create-company-track-location-form';

export const CreateCompanyTrackLocationModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const closeModal = () =>
    navigate('/company/track-locations', { replace: true });

  const isModalOpen = Boolean(
    useMatch({
      path: '/company/track-locations/create',
    }),
  );

  return (
    <Modal
      isModalOpen={Boolean(isModalOpen)}
      closeModal={closeModal}
      title={t('company.track-location.create')}
      description={t('company.track-location.create-details')}
      className="overflow-hidden flex flex-col"
    >
      <CreateCompanyTrackLocationForm closeModal={closeModal} />
    </Modal>
  );
};
