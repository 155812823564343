import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { RestaurantShift } from '@durma-soft/gros-sdk/dist/types/restaurants';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
} from '@/components/shared/shadcn-ui/select';

interface SelectShiftProps {
  id?: string;
  shift: RestaurantShift;
  setShift: Dispatch<SetStateAction<RestaurantShift>>;
  className?: string;
  groupClassname?: string;
}

export const SelectShift = ({
  id = 'shift',
  shift,
  setShift,
  className,
  groupClassname,
}: SelectShiftProps) => {
  const { t } = useTranslation();
  return (
    <Select
      value={shift}
      onValueChange={(shift) => setShift(shift as RestaurantShift)}
    >
      <SelectTrigger className={className} id={id}>
        {shift}. {t('menu.shift')}
      </SelectTrigger>
      <SelectContent>
        <SelectGroup className={groupClassname}>
          <SelectItem value="1">1. {t('menu.shift')}</SelectItem>
          <SelectItem value="2">2. {t('menu.shift')}</SelectItem>
          <SelectItem value="3">3. {t('menu.shift')}</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
