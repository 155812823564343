import { EyeIcon, EyeOff } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { CountryCode } from 'libphonenumber-js';
import { SetStateAction, useId, useMemo, useState } from 'react';
import {
  asYouTypePhoneNumber,
  phoneNumberInputPlaceholder,
} from '@durma-soft/gros-sdk';

import { Label } from '@/components/shared/shadcn-ui/label';
import { Input } from '@/components/shared/shadcn-ui/input';
import { InputAddon } from '@/components/shared/input-addon';
import { SelectCountryCallingCode } from '@/components/shared/select-country-code';

import { RestaurantPartnerFormData } from '@/types/partners';

interface PartnerOwnerProps {
  formData: RestaurantPartnerFormData;
  setFormData: (state: SetStateAction<RestaurantPartnerFormData>) => void;
  ownerCountryCode: CountryCode;
  setOwnerCountryCode: (state: SetStateAction<CountryCode>) => void;
}

export const PartnerOwner = ({
  formData,
  setFormData,
  ownerCountryCode,
  setOwnerCountryCode,
}: PartnerOwnerProps) => {
  const uid = useId();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const doesPasswordsMatch = useMemo(() => {
    return formData.password === formData.password_confirmation;
  }, [formData.password, formData.password_confirmation]);

  return (
    <div className="grid gap-4 p-2">
      {/* Username */}
      <div className="flex flex-col gap-2">
        <Label htmlFor={uid + '-username'}>{t('common.username')}</Label>
        <div className="flex items-stretch w-full">
          <Input
            required
            id={uid + '-username'}
            value={formData.username}
            onChange={(e) =>
              setFormData({ ...formData, username: e.target.value })
            }
            className="flex-1 lowercase border-r-0 rounded-r-none"
            placeholder="marko.markovic"
          />
          <div className="flex items-center justify-center px-3 border border-input rounded-r-md ">
            <span className="text-sm text-gray-700 select-none">
              @{formData.username_suffix}
            </span>
          </div>
        </div>
      </div>
      {/* Email */}
      <div className="flex flex-col gap-2">
        <Label htmlFor={uid + '-email'}>{t('common.email')}</Label>
        <Input
          id={uid + '-email'}
          type="email"
          value={formData.email || ''}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          placeholder={t('common.email-placeholder')}
        />
      </div>
      {/* First name */}
      <div className="flex flex-col gap-2">
        <Label htmlFor={uid + '-first-name'}>{t('common.first-name')}</Label>
        <Input
          required
          id={uid + '-first-name'}
          value={formData.first_name}
          onChange={(e) =>
            setFormData({ ...formData, first_name: e.target.value })
          }
          placeholder={t('common.first-name-placeholder')}
        />
      </div>
      {/* Last name */}
      <div className="flex flex-col gap-2">
        <Label htmlFor={uid + '-last-name'}>{t('common.last-name')}</Label>
        <Input
          required
          id={uid + '-last-name'}
          value={formData.last_name}
          onChange={(e) =>
            setFormData({ ...formData, last_name: e.target.value })
          }
          placeholder={t('common.last-name-placeholder')}
        />
      </div>
      {/* Owner phone number */}
      <div className="flex flex-col gap-2">
        <Label htmlFor={uid + '-phone-number'}>
          {t('common.phone-number')}
        </Label>
        <div className="flex items-stretch">
          <InputAddon position="left">
            <SelectCountryCallingCode
              value={ownerCountryCode}
              onChange={(e) => {
                setOwnerCountryCode(e.target.value as CountryCode);
                setFormData({
                  ...formData,
                  owner_phone_number: '',
                });
              }}
            />
          </InputAddon>
          <Input
            className="flex-1 border-l-0 rounded-tl-none rounded-bl-none"
            id={uid + '-phone-number'}
            placeholder={phoneNumberInputPlaceholder[ownerCountryCode]}
            value={formData.owner_phone_number}
            onChange={(e) =>
              setFormData({
                ...formData,
                owner_phone_number: asYouTypePhoneNumber(
                  e.target.value,
                  ownerCountryCode,
                ),
              })
            }
          />
        </div>
      </div>
      {/* Password */}
      <div className="flex flex-col gap-2">
        <Label htmlFor={uid + '-password'}>{t('common.password')}</Label>
        <div className="flex items-stretch">
          <Input
            required
            id={`${uid}-password`}
            type={showPassword ? 'text' : 'password'}
            value={formData.password}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
            error={
              formData.password_confirmation !== '' &&
              !doesPasswordsMatch &&
              t('common.passwords-must-match')
            }
            className={`flex-1 border-r-0 rounded-tr-none rounded-br-none ${
              formData.password_confirmation !== '' &&
              !doesPasswordsMatch &&
              'border-r'
            } `}
            placeholder={t('common.password-placeholder')}
          />
          <InputAddon
            position="right"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <EyeIcon className="w-4 h-4" />
            ) : (
              <EyeOff className="w-4 h-4" />
            )}
          </InputAddon>
        </div>
      </div>
      {/* Password confirmation */}
      <div className="flex flex-col gap-2">
        <Label htmlFor={uid + '-password-confirmation'}>
          {t('common.password-confirmation')}
        </Label>
        <div className="flex items-stretch">
          <Input
            required
            id={`${uid}-password-confirmation`}
            type={showPassword ? 'text' : 'password'}
            error={
              formData.password_confirmation !== '' &&
              !doesPasswordsMatch &&
              t('common.passwords-must-match')
            }
            value={formData.password_confirmation}
            onChange={(e) =>
              setFormData({
                ...formData,
                password_confirmation: e.target.value,
              })
            }
            className={`flex-1 border-r-0 rounded-tr-none rounded-br-none ${
              formData.password_confirmation !== '' &&
              !doesPasswordsMatch &&
              'border-r'
            } `}
            placeholder={t('common.password-confirmation-placeholder')}
          />
          <InputAddon
            position="right"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <EyeIcon className="w-4 h-4" />
            ) : (
              <EyeOff className="w-4 h-4" />
            )}
          </InputAddon>
        </div>
      </div>
    </div>
  );
};
