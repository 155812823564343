import { COMPANY_USER_ROLE, RESTAURANT_USER_ROLE } from '@durma-soft/gros-sdk';
import {
  Beef,
  Building,
  Building2,
  ClipboardList,
  HandPlatter,
  Hotel,
  Package,
  QrCodeIcon,
  ReceiptText,
  Sandwich,
  Settings,
  Soup,
  TicketCheck,
  UsersRound,
  Utensils,
  UtensilsCrossed,
} from 'lucide-react';

import { TType } from '@/types/general';
import { SidebarItem } from '@/types/sidebar';

const restaurantItems = (t: TType): SidebarItem[] => [
  {
    icon: <Building />,
    link: '/restaurant/menus',
    label: t('sidebar.my-restaurant'),
    subitems: [
      {
        icon: <Utensils />,
        label: t('sidebar.menus'),
        link: '/restaurant/menus',
      },
      {
        icon: <Beef />,
        label: t('sidebar.ingredients'),
        link: '/restaurant/ingredients',
      },
      {
        icon: <Soup />,
        label: t('sidebar.foods'),
        link: '/restaurant/foods',
      },
      {
        icon: <UtensilsCrossed />,
        label: t('sidebar.menu-types'),
        link: '/restaurant/menu-types',
      },
      {
        icon: <UsersRound />,
        label: t('sidebar.restaurant-employees'),
        link: '/restaurant/employees',
      },
      {
        icon: <Hotel />,
        label: t('sidebar.partners'),
        link: '/restaurant/partners',
      },
      {
        icon: <Settings />,
        label: t('sidebar.settings'),
        link: '/restaurant/settings',
        hideFrom: [RESTAURANT_USER_ROLE.EMPLOYEE],
      },
    ],
  },
  {
    icon: <ClipboardList />,
    label: t('sidebar.reports'),
    link: '/restaurant/reports/ingredients',
    subitems: [
      {
        icon: <Beef />,
        label: t('sidebar.report-ingredients'),
        link: '/restaurant/reports/ingredients',
      },
      {
        icon: <Soup />,
        label: t('sidebar.report-foods'),
        link: '/restaurant/reports/foods',
      },
      {
        icon: <TicketCheck />,
        label: t('sidebar.orders'),
        link: '/restaurant/reports/orders',
      },
      {
        icon: <Sandwich />,
        label: t('sidebar.orders-by-food'),
        link: '/restaurant/reports/orders-by-food',
      },
      {
        icon: <Package />,
        label: t('sidebar.package'),
        link: '/restaurant/reports/packages',
      },
      {
        icon: <UsersRound />,
        label: t('sidebar.restaurant-customers'),
        link: '/restaurant/reports/restaurant-customer-orders',
      },
      {
        icon: <Sandwich />,
        label: t('sidebar.substitute-meals'),
        link: '/restaurant/reports/substitute-meals',
      },
      {
        icon: <HandPlatter />,
        label: t('sidebar.restaurants'),
        link: '/restaurant/reports/restaurants',
        hideFrom: [RESTAURANT_USER_ROLE.MANAGER, RESTAURANT_USER_ROLE.EMPLOYEE],
      },
      {
        icon: <ReceiptText />,
        label: t('sidebar.invoicing'),
        link: '/restaurant/reports/invoicing',
        hideFrom: [RESTAURANT_USER_ROLE.MANAGER, RESTAURANT_USER_ROLE.EMPLOYEE],
      },
    ],
  },
  {
    icon: <Building2 />,
    label: t('sidebar.companies'),
    link: '/restaurant/companies',
  },
];

const companyItems = (t: TType): SidebarItem[] => [
  {
    icon: <Building />,
    link: '/company/locations',
    label: t('sidebar.my-company'),
    hideFrom: [COMPANY_USER_ROLE.TRACK_EMPLOYEE, COMPANY_USER_ROLE.EMPLOYEE],
    subitems: [
      {
        icon: <Building2 />,
        label: t('sidebar.locations'),
        link: '/company/locations',
      },
      {
        icon: <UsersRound />,
        label: t('sidebar.employees'),
        link: '/company/employees',
      },
      {
        icon: <QrCodeIcon />,
        label: t('sidebar.track-locations'),
        link: '/company/track-locations',
      },
    ],
  },
  {
    icon: <ClipboardList />,
    label: t('sidebar.reports'),
    link: '/company/reports/foods',
    hideFrom: [COMPANY_USER_ROLE.TRACK_EMPLOYEE, COMPANY_USER_ROLE.EMPLOYEE],
    subitems: [
      {
        icon: <Soup />,
        label: t('sidebar.report-foods'),
        link: '/company/reports/foods',
      },
      {
        icon: <UsersRound />,
        label: t('sidebar.restaurant-customers'),
        link: '/company/reports/customer-orders',
      },
      {
        icon: <TicketCheck />,
        label: t('sidebar.orders'),
        link: '/company/reports/orders',
      },
      {
        icon: <Sandwich />,
        label: t('sidebar.orders-by-food'),
        link: '/company/reports/orders-by-food',
      },
    ],
  },
  {
    icon: <QrCodeIcon />,
    label: t('sidebar.track'),
    link: '/company/track',
    hideFrom: [
      COMPANY_USER_ROLE.OWNER,
      COMPANY_USER_ROLE.MANAGER,
      COMPANY_USER_ROLE.EMPLOYEE,
    ],
  },
];

export const config = (t: TType) => {
  return {
    restaurantItems: restaurantItems(t),
    companyItems: companyItems(t),
  };
};
