import { toast } from 'sonner';
import { Image } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { CountryCode } from 'libphonenumber-js';
import { FormEvent, useId, useState } from 'react';
import { UserRestaurant } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  ValidationError,
  useEditRestaurantInformationMutation,
  asYouTypePhoneNumber,
  parsePhoneNumber,
  phoneNumberInputPlaceholder,
} from '@durma-soft/gros-sdk';

import { Input } from '@/components/shared/shadcn-ui/input';
import { Label } from '@/components/shared/shadcn-ui/label';
import { InputAddon } from '@/components/shared/input-addon';
import { TimePicker } from '@/components/shared/time-picker';
import { Button } from '@/components/shared/shadcn-ui/button';
import { CustomFileInput } from '@/components/shared/custom-file-input';
import { SelectCountryCallingCode } from '@/components/shared/select-country-code';

import { Time } from '@/types/general';
import { formatTime } from '@/utils/helpers';
import { useChangedObject } from '@/hooks/use-changed-object';

interface FormState {
  name: string;
  phone_number: string;
  street: string;
  city: string;
  state: string;
  country: string;
  logo: File | null | string;
  time: Time;
  zip: string;
  username_suffix: string;
}

interface RestaurantInformationProps {
  restaurant: UserRestaurant;
}

export const EditRestaurantInformationForm = ({
  restaurant,
}: RestaurantInformationProps) => {
  const { t } = useTranslation();

  const [hour, minute] = restaurant.order_cutoff_time.split(':');

  const [initialFormData] = useState<FormState>({
    name: restaurant.name,
    phone_number: restaurant.phone_number || '',
    street: restaurant.street || '',
    city: restaurant.city || '',
    state: restaurant.state || '',
    country: restaurant.country || '',
    logo: restaurant.logo,
    time: { hour, minute },
    zip: restaurant.zip || '',
    username_suffix: restaurant.username_suffix,
  });

  const [formData, setFormData] = useState<FormState>({
    name: restaurant.name,
    phone_number:
      parsePhoneNumber(restaurant.phone_number)?.formatNational() ||
      restaurant.phone_number ||
      '',
    street: restaurant.street || '',
    city: restaurant.city || '',
    state: restaurant.state || '',
    country: restaurant.country || '',
    logo: restaurant.logo,
    time: { hour, minute },
    zip: restaurant.zip || '',
    username_suffix: restaurant.username_suffix,
  });

  const [countryCode, setCountryCode] = useState<CountryCode>(
    parsePhoneNumber(restaurant.phone_number)?.country ||
      import.meta.env.VITE_DEFAULT_COUNTRY_CODE ||
      'BA',
  );

  const uid = useId();
  const editRestaurantInformation = useEditRestaurantInformationMutation();

  const handleTimeChange = (hour: string, minute: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      time: { hour, minute },
    }));
  };

  const [isChanged, changedObj] = useChangedObject(formData, initialFormData, {
    customComparators: {
      time: (a, b) => {
        return (
          (a as Time).hour === (b as Time).hour &&
          (a as Time).minute === (b as Time).minute
        );
      },
      phone_number: (a, b) => {
        if (!a && !b) return true;
        return (
          parsePhoneNumber(a as string, countryCode)?.format('E.164') === b
        );
      },
    },
  });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await editRestaurantInformation.mutateAsync({
        restaurant_id: restaurant.id,
        ...changedObj,
        phone_number: changedObj.phone_number
          ? parsePhoneNumber(formData.phone_number, countryCode)?.format(
              'E.164',
            ) || ''
          : undefined,
        logo: typeof formData.logo === 'string' ? undefined : formData.logo,
        order_cutoff_time: changedObj.time
          ? formatTime(formData.time)
          : undefined,
      });
      toast.success(t('settings.edit-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('settings.edit-error-msg'));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col items-start gap-8">
        {/* Details */}
        <div className="grid gap-4">
          {/* Logo */}
          <div className="flex flex-col w-1/2 gap-4">
            <div className="w-32 h-32">
              {!formData.logo && (
                <div className="inline-flex items-center justify-center w-full h-full border rounded-md border-border">
                  <Image className="w-6 h-6" />
                </div>
              )}
              {formData.logo && (
                <div>
                  <img
                    src={
                      typeof formData.logo === 'string'
                        ? formData.logo
                        : URL.createObjectURL(formData.logo)
                    }
                    height="128"
                    width="128"
                    className="object-contain object-center w-32 h-32 rounded-md"
                  />
                </div>
              )}
            </div>
            <CustomFileInput
              label={t('common.add-logo')}
              hasDeleteButton={Boolean(formData.logo)}
              onDelete={() => setFormData({ ...formData, logo: null })}
              handleFile={(file) => {
                setFormData({ ...formData, logo: file });
              }}
            />
          </div>
          <div className="grid grid-cols-2 gap-6">
            {/* Name */}
            <div className="flex flex-col gap-2">
              <Label htmlFor={uid + '-name'}>{t('common.name')}</Label>
              <div className="flex items-stretch w-full">
                <Input
                  id={uid + '-name'}
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  placeholder={t('common.name-placeholder')}
                />
              </div>
            </div>
            {/* Username suffix */}
            <div className="flex flex-col gap-2">
              <Label htmlFor={uid + '-username-suffix'}>
                {t('settings.suffix')}
              </Label>
              <div className="flex items-stretch">
                <InputAddon position="left">@</InputAddon>
                <Input
                  required
                  className="flex-1 border-l-0 rounded-tl-none rounded-bl-none"
                  id={uid + '-username-suffix'}
                  value={formData.username_suffix || ''}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      username_suffix: e.target.value,
                    })
                  }
                  placeholder={t('settings.suffix-placeholder')}
                />
              </div>
            </div>
            {/* Phone number */}
            <div className="flex flex-col gap-2">
              <Label htmlFor={uid + '-phone-number'}>
                {t('company.phone-number')}
              </Label>
              <div className="flex items-stretch">
                <InputAddon position="left">
                  <SelectCountryCallingCode
                    value={countryCode}
                    onChange={(e) => {
                      setCountryCode(e.target.value as CountryCode);
                      setFormData({
                        ...formData,
                        phone_number: '',
                      });
                    }}
                  />
                </InputAddon>
                <Input
                  className="flex-1 border-l-0 rounded-tl-none rounded-bl-none"
                  id={uid + '-phone-number'}
                  value={formData.phone_number}
                  placeholder={phoneNumberInputPlaceholder[countryCode]}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      phone_number: asYouTypePhoneNumber(
                        e.target.value,
                        countryCode,
                      ),
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <h3 className="my-2 text-lg">{t('common.address')}</h3>
            <div className="grid grid-cols-2 gap-6">
              {/* Street */}
              <div className="flex flex-col gap-2">
                <Label htmlFor={uid + '-street'}>
                  {t('common.address-details.street')}
                </Label>
                <div className="flex items-stretch w-full">
                  <Input
                    id={uid + '-street'}
                    value={formData.street || ''}
                    onChange={(e) =>
                      setFormData({ ...formData, street: e.target.value })
                    }
                    placeholder={t('common.address-details.street-placeholder')}
                  />
                </div>
              </div>
              {/* City */}
              <div className="flex flex-col gap-2">
                <Label htmlFor={uid + '-city'}>
                  {t('common.address-details.city')}
                </Label>
                <div className="flex items-stretch w-full">
                  <Input
                    id={uid + '-city'}
                    value={formData.city || ''}
                    onChange={(e) =>
                      setFormData({ ...formData, city: e.target.value })
                    }
                    placeholder={t('common.address-details.city-placeholder')}
                  />
                </div>
              </div>
              {/* Zip */}
              <div className="flex flex-col gap-2">
                <Label htmlFor={uid + '-zip'}>
                  {t('common.address-details.zip')}
                </Label>
                <div className="flex items-stretch w-full">
                  <Input
                    id={uid + '-zip'}
                    value={formData.zip || ''}
                    onChange={(e) =>
                      setFormData({ ...formData, zip: e.target.value })
                    }
                    placeholder={t('common.address-details.zip-placeholder')}
                  />
                </div>
              </div>
              {/* State */}
              <div className="flex flex-col gap-2">
                <Label htmlFor={uid + '-state'}>
                  {t('common.address-details.state')}
                </Label>
                <div className="flex items-stretch w-full">
                  <Input
                    id={uid + '-state'}
                    value={formData.state || ''}
                    onChange={(e) =>
                      setFormData({ ...formData, state: e.target.value })
                    }
                    placeholder={t('common.address-details.state-placeholder')}
                  />
                </div>
              </div>
              {/* Country */}
              <div className="flex flex-col gap-2">
                <Label htmlFor={uid + '-country'}>
                  {t('common.address-details.country')}
                </Label>
                <div className="flex items-stretch w-full">
                  <Input
                    id={uid + '-country'}
                    value={formData.country || ''}
                    onChange={(e) =>
                      setFormData({ ...formData, country: e.target.value })
                    }
                    placeholder={t(
                      'common.address-details.country-placeholder',
                    )}
                  />
                </div>
              </div>
              {/* Time */}
              <div>
                <Label>{t('settings.order-deadline')}</Label>
                <TimePicker
                  time={formData.time}
                  handleTimeChange={handleTimeChange}
                />
              </div>
            </div>
          </div>
        </div>
        <Button
          disabled={!isChanged}
          type="submit"
          isLoading={editRestaurantInformation.isPending}
        >
          {t('common.save-edit')}
        </Button>
      </div>
    </form>
  );
};
