import { useTranslation } from 'react-i18next';
import { LocationType } from '@durma-soft/gros-sdk';
import { Dispatch, SetStateAction, useId } from 'react';
import { RestaurantMenuType } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { Alert } from '@/components/shared/alert';
import { Label } from '@/components/shared/shadcn-ui/label';
import { Input } from '@/components/shared/shadcn-ui/input';
import { Checkbox } from '@/components/shared/shadcn-ui/checkbox';
import { CompanyLocationTrackLocations } from '@/components/restaurant/companies/company-location-track-locations';
import {
  RadioGroup,
  RadioGroupItem,
} from '@/components/shared/shadcn-ui/radio-group';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/shared/shadcn-ui/select';

import { CompanyLocationFormData } from '@/types/companies';
import { locationDeliveryTypes } from '@/config/restaurant/company-locations';

interface CompanyLocationDetailsProps {
  menuTypes: RestaurantMenuType[];
  isLoadingMenuTypes?: boolean;
  formData: CompanyLocationFormData;
  setFormData: Dispatch<SetStateAction<CompanyLocationFormData>>;
}

export const CompanyLocationDetails = ({
  menuTypes,
  isLoadingMenuTypes = false,
  formData,
  setFormData,
}: CompanyLocationDetailsProps) => {
  const uid = useId();
  const { t } = useTranslation();

  return (
    <div className="grid gap-4">
      {/* Name */}
      <div className="flex flex-col gap-2">
        <Label htmlFor={uid + '-name'}>{t('common.name')}</Label>
        <Input
          required
          id={uid + '-name'}
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          placeholder={t('common.name-placeholder')}
        />
      </div>

      {/* Menu type */}
      <div className="flex flex-col gap-2">
        <Label htmlFor={uid + '-menu-type'}>{t('common.menu-type')}</Label>
        {!isLoadingMenuTypes && menuTypes?.length === 0 ? (
          <Alert
            title={t('menu-type.not-entered')}
            description={t('menu-type.not-entered-description')}
            variant="destructive"
          />
        ) : (
          <Select
            value={
              formData.menu_type_id ? `${formData.menu_type_id}` : undefined
            }
            onValueChange={(e) =>
              setFormData({
                ...formData,
                menu_type_id: +e,
              })
            }
          >
            <SelectTrigger id={uid + '-menu-type'}>
              <SelectValue placeholder={t('menu.choose-menu-type')} />
            </SelectTrigger>
            <SelectContent>
              {menuTypes &&
                menuTypes?.map(({ id, name }) => (
                  <SelectItem key={id} value={`${id}`}>
                    {name}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
        )}
      </div>

      {/* Location type */}
      <div className="flex flex-col gap-2">
        <Label>{t('common.location-type')}</Label>
        <RadioGroup
          value={formData.location_type || 'direct'}
          onValueChange={(e) =>
            setFormData((prevData) => {
              return { ...prevData, location_type: e as LocationType };
            })
          }
        >
          {locationDeliveryTypes(t)?.map(({ value, label }) => (
            <div key={value} className="flex items-center space-x-2">
              <RadioGroupItem value={value} id={uid + value} />
              <Label htmlFor={uid + value}>{label}</Label>
            </div>
          ))}
        </RadioGroup>
      </div>
      {formData.location_type === 'track' && (
        <CompanyLocationTrackLocations
          formData={formData}
          setCompanyTrackLocationId={(newId) =>
            setFormData((prevFormData) => ({
              ...prevFormData,
              company_track_location_id: newId,
            }))
          }
        />
      )}
      {/* Shift/s */}
      <div className="flex flex-col gap-2">
        <Label>{t('common.shifts')}</Label>
        <div className="flex items-center gap-1.5">
          <Checkbox
            id={uid + '-shift-1'}
            checked={formData.shift_1}
            onCheckedChange={(checked) =>
              setFormData((prevData) => {
                return { ...prevData, shift_1: Boolean(checked) };
              })
            }
          />
          <label
            htmlFor={uid + '-shift-1'}
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {t('shifts.first')}
          </label>
        </div>
        <div className="flex items-center gap-1.5">
          <Checkbox
            id={uid + '-shift-2'}
            checked={formData.shift_2}
            onCheckedChange={(checked) =>
              setFormData((prevData) => {
                return { ...prevData, shift_2: Boolean(checked) };
              })
            }
          />
          <label
            htmlFor={uid + '-shift-2'}
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {t('shifts.second')}
          </label>
        </div>
        <div className="flex items-center gap-1.5">
          <Checkbox
            id={uid + '-shift-3'}
            checked={formData.shift_3}
            onCheckedChange={(checked) =>
              setFormData((prevData) => {
                return { ...prevData, shift_3: Boolean(checked) };
              })
            }
          />
          <label
            htmlFor={uid + '-shift-3'}
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {t('shifts.third')}
          </label>
        </div>
      </div>
    </div>
  );
};
