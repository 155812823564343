import { PrinterIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/shared/shadcn-ui/button';
import { cn, noop } from '@/utils/helpers';

interface PrintButtonProps {
  className?: string;
  iconClassName?: string;
  variant?:
    | 'default'
    | 'destructive'
    | 'outline'
    | 'secondary'
    | 'ghost'
    | 'link'
    | null
    | undefined;
  onClick?: () => void;
}

export const PrintButton = ({
  className,
  iconClassName,
  variant = 'default',
  onClick = noop,
}: PrintButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      variant={variant}
      onClick={onClick}
      className={cn('print:hidden flex gap-1.5', className)}
    >
      <span>{t('common.print')}</span>
      <PrinterIcon className={cn('w-4 h-4', iconClassName)} />
    </Button>
  );
};
