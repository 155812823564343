import { toast } from 'sonner';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { FormEvent, useId, useMemo, useState } from 'react';
import { RestaurantIngredient } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  availableUnits,
  convertUnits,
  PrimaryUnit,
  Unit,
  useAuthUserRestaurant,
  useUpdateIngredientQuantityMutation,
  ValidationError,
} from '@durma-soft/gros-sdk';
import { Label } from '@/components/shared/shadcn-ui/label';
import { Input } from '@/components/shared/shadcn-ui/input';
import { InputAddon } from '@/components/shared/input-addon';
import { DatePicker } from '@/components/shared/date-picker';
import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/shared/shadcn-ui/select';

import { cn, isNotEmpty } from '@/utils/helpers';
import { inputTypes } from '@/config/restaurant/food-ingredients';
import { SESSION_STORAGE_INGREDIENT_QUANTITY_INPUT_TYPE_KEY } from '@/config/global/storage';

interface EditRestaurantIngredientQuantityForm {
  ingredient: RestaurantIngredient;
  closeModal: () => unknown;
  date: string;
  setDate: (date: string) => unknown;
}

type InputType = 'add' | 'subtract' | 'new_state';

interface FormData {
  input_type: InputType;
  quantity: number | string;
  unit: Unit;
}

export const EditRestaurantIngredientQuantityForm = ({
  ingredient,
  closeModal,
  date,
  setDate,
}: EditRestaurantIngredientQuantityForm) => {
  const uid = useId();
  const { t } = useTranslation();
  const restaurant = useAuthUserRestaurant();

  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const [formData, setFormData] = useState<FormData>({
    input_type:
      (sessionStorage.getItem(
        SESSION_STORAGE_INGREDIENT_QUANTITY_INPUT_TYPE_KEY,
      ) as InputType) || 'add',
    quantity: '',
    unit: (ingredient.preferred_display_unit || ingredient.unit) as Unit,
  });

  const units = availableUnits[ingredient.unit as PrimaryUnit];

  const updateRestaurantIngredientQuantity =
    useUpdateIngredientQuantityMutation();

  const isValid = useMemo(() => {
    return isNotEmpty(`${formData.quantity}`);
  }, [formData.quantity]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;

    try {
      await updateRestaurantIngredientQuantity.mutateAsync({
        date,
        restaurant_id: restaurant.id,
        ingredient_id: ingredient.id,
        quantity: Number(formData.quantity),
        type: formData.input_type,
      });

      closeModal();
      toast.success(t('ingredient.edit-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('ingredient.edit-error-msg'));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <DatePicker
        open={datePickerOpen}
        onOpenChange={setDatePickerOpen}
        date={new Date(date)}
        setDate={(newDate) => {
          if (!newDate) return;
          setDate(format(newDate, 'yyyy-MM-dd'));
          setDatePickerOpen(false);
        }}
      />
      <div className="grid gap-4 py-4">
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-input-type'}>
            {t('ingredient.input-type.label')}
          </Label>
          <Select
            required
            value={formData.input_type}
            onValueChange={(newValue) => {
              setFormData({ ...formData, input_type: newValue as InputType });
              sessionStorage.setItem(
                SESSION_STORAGE_INGREDIENT_QUANTITY_INPUT_TYPE_KEY,
                newValue,
              );
            }}
          >
            <SelectTrigger id={uid + '-input-type'}>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {inputTypes(t).map(({ value, name }) => (
                <SelectItem key={value} value={value}>
                  {name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-quantity'}>{t('common.quantity')}</Label>
          <div className="flex items-stretch">
            {formData.input_type === 'subtract' && (
              <InputAddon position="left">-</InputAddon>
            )}
            <Input
              type="number"
              required
              id={uid + '-quantity'}
              className={cn(
                'flex-1 border-r-0 rounded-r-none',
                formData.input_type === 'subtract' &&
                  'border-l-0 rounded-l-none',
              )}
              inputMode="numeric"
              value={
                (formData.quantity as unknown) === ''
                  ? ''
                  : convertUnits({
                      from: ingredient.unit as Unit,
                      to: formData.unit,
                      quantity: formData.quantity as number,
                    })
              }
              onChange={(e) => {
                setFormData({
                  ...formData,
                  quantity:
                    e.target.value === ''
                      ? ''
                      : convertUnits({
                          from: formData.unit,
                          to: ingredient.unit as Unit,
                          quantity: Number(e.target.value),
                        }),
                });
              }}
            />
            <InputAddon>
              <select
                value={formData.unit}
                onChange={(e) => {
                  setFormData((prevState) => {
                    return { ...prevState, unit: e.target.value as Unit };
                  });
                }}
                className="focus:outline-none bg-transparent"
              >
                {units.map((unit) => (
                  <option key={unit} value={unit}>
                    {t(`common.measure-unit-short.${unit}`)}
                  </option>
                ))}
              </select>
            </InputAddon>
          </div>
        </div>
      </div>
      <DialogFooter className="mt-2">
        <Button
          type="submit"
          isLoading={updateRestaurantIngredientQuantity.isPending}
          disabled={!isValid}
        >
          {t('common.save-edit')}
        </Button>
      </DialogFooter>
    </form>
  );
};
