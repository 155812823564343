import { SetStateAction } from 'react';
import { useAuthUserRestaurant } from '@durma-soft/gros-sdk';

import { IngredientsTable } from '@/components/restaurant/foods/ingredients-table';
import { IngredientCombobox } from '@/components/restaurant/foods/ingredient-combobox';

import {
  RestaurantFoodFormData,
  ShortRestaurantIngredientWithQuantity,
} from '@/types/foods';

interface IngredientsTabProps {
  ingredients: ShortRestaurantIngredientWithQuantity[];
  setFormData: (state: SetStateAction<RestaurantFoodFormData>) => void;
}

export const IngredientsTab = ({
  ingredients,
  setFormData,
}: IngredientsTabProps) => {
  const restaurant = useAuthUserRestaurant();
  return (
    <div className="flex flex-col gap-4 p-2">
      <IngredientCombobox
        addPosition="start"
        restaurantId={restaurant.id}
        selectedIngredients={ingredients ?? []}
        onUpdateIngredients={(newIngredients) => {
          setFormData((oldFormData) => ({
            ...oldFormData,
            ingredients: newIngredients,
          }));
        }}
      />
      <IngredientsTable ingredients={ingredients} setFormData={setFormData} />
    </div>
  );
};
