import { SetStateAction, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from '@/components/shared/shadcn-ui/input';
import { Label } from '@/components/shared/shadcn-ui/label';
import { Checkbox } from '@/components/shared/shadcn-ui/checkbox';
import { CustomFileInput } from '@/components/shared/custom-file-input';

import { foodAttributes } from '@/config/restaurant/foods';
import { RestaurantFoodFormData } from '@/types/foods';

interface FoodDetailsProps {
  formData: RestaurantFoodFormData;
  setFormData: (state: SetStateAction<RestaurantFoodFormData>) => void;
}

export const FoodDetails = ({ formData, setFormData }: FoodDetailsProps) => {
  const uid = useId();
  const { t } = useTranslation();

  return (
    <div className="grid gap-4 p-2">
      {/* Name */}
      <div className="flex flex-col gap-2">
        <Label htmlFor={uid + '-name'}>{t('common.name')}</Label>
        <Input
          required
          id={uid + '-name'}
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          placeholder={t('common.name-placeholder')}
        />
      </div>
      {/* Food attributes */}
      <div className="flex flex-col gap-2">
        {foodAttributes(t)?.map(({ id, label }) => (
          <div className="flex items-center gap-3 mb-2" key={id}>
            <Checkbox
              id={id}
              checked={formData[id as keyof RestaurantFoodFormData] as boolean}
              onCheckedChange={() =>
                setFormData((prevData) => ({
                  ...prevData,
                  [id]: !prevData[id as keyof RestaurantFoodFormData],
                }))
              }
            />
            <label
              htmlFor={id}
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              {label}
            </label>
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-4">
        {/* Food picture */}
        <div className="flex flex-col gap-2">
          <CustomFileInput
            label={t('common.add-image')}
            hasDeleteButton={Boolean(formData.picture)}
            onDelete={() => setFormData({ ...formData, picture: null })}
            handleFile={(picture) => setFormData({ ...formData, picture })}
          />
          <div>
            {formData.picture && (
              <img
                src={
                  typeof formData.picture === 'string'
                    ? formData.picture
                    : URL.createObjectURL(formData.picture)
                }
                height="128"
                width="128"
                className="object-contain object-center w-32 h-32 rounded-md"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
