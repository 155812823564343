import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
} from '@durma-soft/gros-sdk';

import { EditRestaurantInformationForm } from '@/components/restaurant/settings/edit-restaurant-information';

import { Alert } from '@/components/shared/alert';

const RestaurantSettings = () => {
  const { t } = useTranslation();
  const restaurant = useAuthUserRestaurant();
  const hasEditPermission = restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;

  return (
    <>
      <Helmet>
        <title>{t('settings.title')}</title>
      </Helmet>
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl">
          {t('settings.title')}
        </h1>
      </div>
      <div className="flex flex-col gap-2 mt-4">
        {hasEditPermission ? (
          <EditRestaurantInformationForm restaurant={restaurant} />
        ) : (
          <Alert
            variant="destructive"
            title={t('common.edit-not-allowed')}
            description={t('common.edit-not-allowed-description')}
          />
        )}
      </div>
    </>
  );
};

export default RestaurantSettings;
