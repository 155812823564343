import { format } from 'date-fns';
import { TZDate } from '@date-fns/tz';
import { ColumnDef } from '@tanstack/react-table';
import { OrdersBySingleEmployeeReportOrder } from '@durma-soft/gros-sdk/dist/types/reports';

import { TType } from '@/types/general';

export const columns = (
  t: TType,
): ColumnDef<OrdersBySingleEmployeeReportOrder>[] => [
  {
    header: t('common.order-number'),
    cell: ({ row: { index } }) => <>{Number(index + 1)}</>,
  },
  {
    accessorKey: 'date',
    header: t('common.date'),
    cell: (cellData) => {
      return format(cellData.row.original.date, 'd.M.yyyy');
    },
  },
  {
    accessorKey: 'food_name',
    header: t('menu.foods'),
  },
  {
    accessorKey: 'is_substitute',
    header: t('reports.is-substitute'),
    cell: (cellData) => {
      if (cellData.row.original.location_type !== 'track') return '/';
      return cellData.row.original.is_substitute
        ? t('common.yes')
        : t('common.no');
    },
  },
  {
    accessorKey: 'picked_up_at',
    header: t('reports.picked-up-at'),
    cell: (cellData) => {
      if (cellData.row.original.location_type !== 'track') return '/';
      const pickedUpAt = cellData.row.original.picked_up_at;
      if (!pickedUpAt) return '/';
      const utcDate = pickedUpAt.replace(' ', 'T') + 'Z';
      const tzDate = new TZDate(utcDate);

      const hours = tzDate.getHours();
      const minutes =
        tzDate.getMinutes() > 9
          ? tzDate.getMinutes()
          : `0${tzDate.getMinutes()}`;

      return `${hours}:${minutes} h`;
    },
  },
];
