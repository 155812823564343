import { useTranslation } from 'react-i18next';
import { useAuthContext } from '@durma-soft/gros-sdk';
import { FC, ReactNode, useLayoutEffect, useState } from 'react';

import { Spinner } from '@/components/shared/spinner';

export const UserLocaleMiddleware: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const { i18n } = useTranslation();
  const { user } = useAuthContext();
  const [languageLoaded, setLanguageLoaded] = useState(false);

  useLayoutEffect(() => {
    if (user?.user?.locale) {
      i18n.changeLanguage(user.user.locale).finally(() => {
        setLanguageLoaded(true);
      });
    } else {
      setLanguageLoaded(true);
    }
  }, [user, i18n]);

  if (!languageLoaded) {
    return (
      <div className="grid h-full place-items-center">
        <Spinner />
      </div>
    );
  }

  return <>{children}</>;
};
