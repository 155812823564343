import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { FormEvent, useMemo, useState } from 'react';
import { ShortRestaurantFood } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  ValidationError,
  useAuthUserRestaurant,
  useCreateMenuMutation,
  useGetMenuTypesQuery,
} from '@durma-soft/gros-sdk';

import { Button } from '@/components/shared/shadcn-ui/button';
import { FoodsTab } from '@/components/restaurant/menus/foods-tab';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { CreateMenuDetails } from '@/components/restaurant/menus/create-menu-details';

import { RestaurantMenuFormData } from '@/types/menus';

interface CreateRestaurantMenuFormProps {
  closeModal: () => unknown;
}

export const CreateRestaurantMenuForm = ({
  closeModal,
}: CreateRestaurantMenuFormProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const date = searchParams.get('date');

  const [formData, setFormData] = useState<RestaurantMenuFormData>({
    foods: [],
    shifts: [],
    menu_type_id: null,
    is_active: false,
  });

  const restaurant = useAuthUserRestaurant();
  const createRestaurantMenu = useCreateMenuMutation();

  const { data: menuTypes, isLoading } = useGetMenuTypesQuery(restaurant.id);

  const isValid = useMemo(() => {
    return Boolean(
      formData.shifts.length && formData.foods.length && formData.menu_type_id,
    );
  }, [formData]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;
    try {
      await createRestaurantMenu.mutateAsync({
        restaurant_id: restaurant.id,
        date: date || '',
        foods: formData.foods.map((food) => food.id),
        shifts: formData.shifts,
        menu_type_id: formData.menu_type_id as number,
        is_active: formData.is_active,
      });
      closeModal();
      toast.success(t('menu.create-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('menu.create-error-msg'));
    }
  };

  const onUpdateFoods = (newFoods: ShortRestaurantFood[]) => {
    setFormData((oldFormData) => ({
      ...oldFormData,
      foods: newFoods,
    }));
  };

  const onDeleteFood = (id: number) => {
    setFormData((oldFormData) => ({
      ...oldFormData,
      foods: oldFormData.foods.filter((food) => food.id !== id),
    }));
  };

  if (isLoading) return <ModalFormSkeleton />;

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col flex-1 gap-6 overflow-hidden"
    >
      <div className="flex flex-col flex-1 gap-4 px-2 overflow-y-auto">
        <CreateMenuDetails
          menuTypes={menuTypes || []}
          formData={formData}
          setFormData={setFormData}
        />
        <FoodsTab
          foods={formData.foods}
          onUpdateFoods={onUpdateFoods}
          onDeleteFood={onDeleteFood}
        />
      </div>
      <DialogFooter className="self-end">
        <Button
          type="submit"
          disabled={!isValid}
          isLoading={createRestaurantMenu.isPending}
        >
          {t('menu.create')}
        </Button>
      </DialogFooter>
    </form>
  );
};
