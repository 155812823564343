import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { FormEvent, useMemo, useState } from 'react';
import {
  ValidationError,
  useEditMenuMutation,
  useAuthUserRestaurant,
} from '@durma-soft/gros-sdk';
import {
  RestaurantMenu,
  ShortRestaurantFood,
} from '@durma-soft/gros-sdk/dist/types/restaurants';

import { Button } from '@/components/shared/shadcn-ui/button';
import { FoodsTab } from '@/components/restaurant/menus/foods-tab';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import { EditMenuDetails } from '@/components/restaurant/menus/edit-menu-details';

import { useChangedObject } from '@/hooks/use-changed-object';

import { EditRestaurantMenuFormData } from '@/types/menus';

interface EditRestaurantMenuForm {
  menu: RestaurantMenu;
  closeModal: () => unknown;
}

export const EditRestaurantMenuForm = ({
  menu,
  closeModal,
}: EditRestaurantMenuForm) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<EditRestaurantMenuFormData>({
    foods: menu.foods,
    shift: menu.shift,
    menu_type: menu.menu_type,
  });

  const restaurant = useAuthUserRestaurant();
  const editRestaurantMenu = useEditMenuMutation();

  const [isChanged, changedObj] = useChangedObject(formData, menu);

  const isValid = useMemo(() => {
    return isChanged;
  }, [isChanged]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isChanged) return;

    try {
      await editRestaurantMenu.mutateAsync({
        restaurant_id: restaurant.id,
        menu_id: menu.id,
        ...changedObj,
        foods: formData.foods.map((food) => food.id),
      });
      closeModal();
      toast.success(t('menu.edit-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('menu.edit-error-msg'));
    }
  };

  const onUpdateFoods = (newFoods: ShortRestaurantFood[]) => {
    setFormData((oldFormData) => ({
      ...oldFormData,
      foods: newFoods,
    }));
  };

  const onDeleteFood = (id: number) => {
    setFormData((oldFormData) => ({
      ...oldFormData,
      foods: oldFormData.foods.filter((food) => food.id !== id),
    }));
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col flex-1 gap-6 overflow-hidden"
    >
      <div className="flex flex-col flex-1 gap-4 px-2 overflow-y-auto">
        <EditMenuDetails formData={formData} />
        <FoodsTab
          foods={formData.foods}
          onUpdateFoods={onUpdateFoods}
          onDeleteFood={onDeleteFood}
        />
      </div>
      <DialogFooter className="self-end">
        <Button
          type="submit"
          disabled={!isValid}
          isLoading={editRestaurantMenu.isPending}
        >
          {t('common.save-edit')}
        </Button>
      </DialogFooter>
    </form>
  );
};
