import { useEffect, useRef } from 'react';
import { Balancer } from 'react-wrap-balancer';
import { useTranslation } from 'react-i18next';
import { RestaurantShift } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  useAuthUserCompany,
  useGetTrackPickedUpOrdersQuery,
} from '@durma-soft/gros-sdk';

import { repeat } from '@/utils/helpers';

import { Alert } from '@/components/shared/alert';
import { Card } from '@/components/shared/shadcn-ui/card';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';

interface PickedUpOrdersProps {
  shift: RestaurantShift;
  trackLocationId: number;
  date: string;
}

export const PickedUpOrders = ({
  shift,
  trackLocationId,
  date,
}: PickedUpOrdersProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { id: company_id } = useAuthUserCompany();
  const { t } = useTranslation();

  const { data, isLoading, isSuccess, isError } =
    useGetTrackPickedUpOrdersQuery({
      company_id,
      shift,
      company_track_location_id: trackLocationId,
      date,
    });

  useEffect(() => {
    if (isSuccess) {
      containerRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [data, isSuccess]);

  return (
    <div
      ref={containerRef}
      className="fixed right-0 top-14 lg:top-15 bottom-0 w-64 overflow-y-auto flex flex-col gap-6 bg-muted/40 border-l p-5"
    >
      {isLoading &&
        repeat(10).map((_, i) => <Skeleton className="h-32" key={i} />)}
      {isError && (
        <div>
          <Alert
            variant="destructive"
            title={t('track.picked-up-orders.previous-orders-error')}
            description={t('track.picked-up-orders.previous-orders-error-msg')}
            className="xl:[&>h5]:text-4xl xl:[&>div]:text-xl xl:max-w-3xl xl:[&>svg]:w-10 xl:[&>svg]:h-10 xl:[&>svg~*]:pl-14"
          />
        </div>
      )}
      {isSuccess && data.length === 0 && (
        <p className="text-center text-muted-foreground text-sm xl:text-xl">
          <Balancer>{t('track.picked-up-orders.placeholder')}</Balancer>
        </p>
      )}
      {isSuccess &&
        data.length > 0 &&
        data.map(({ user, foods }) => (
          <Card className="p-4" key={user.id}>
            <p className="text-sm font-semibold mb-3 xl:text-xl">
              {user.first_name} {user.last_name} ( #{user.id} )
            </p>
            <ul className="space-y-2 text-sm xl:space-y-4">
              {foods.map((food) => (
                <li key={food.id}>
                  <span className="font-medium xl:text-xl">
                    {food.quantity}x
                  </span>{' '}
                  <span className="xl:text-xl">{food.name}</span>
                </li>
              ))}
            </ul>
          </Card>
        ))}
    </div>
  );
};
