import { useId } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useAuthUserRestaurant,
  useGetRestaurantCompanyTrackLocationsQuery,
} from '@durma-soft/gros-sdk';

import { Alert } from '@/components/shared/alert';
import { Spinner } from '@/components/shared/spinner';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/shared/shadcn-ui/select';

import {
  CompanyLocationFormData,
  SingleCompanyLocationFormData,
} from '@/types/companies';

interface CompanyLocationTrackLocationsProps {
  formData: CompanyLocationFormData | SingleCompanyLocationFormData;
  setCompanyTrackLocationId: (id: number | null) => void;
}

export const CompanyLocationTrackLocations = ({
  formData,
  setCompanyTrackLocationId,
}: CompanyLocationTrackLocationsProps) => {
  const uid = useId();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const restaurant = useAuthUserRestaurant();

  const { data: trackLocations, isLoading } =
    useGetRestaurantCompanyTrackLocationsQuery({
      restaurant_id: restaurant.id,
      company_id: Number(companyId),
    });

  if (isLoading) return <Spinner />;

  if (!isLoading && trackLocations?.length === 0)
    return (
      <Alert
        variant="destructive"
        title={t('common.no-results')}
        description={t('company.track-location.no-results-description')}
      />
    );

  return (
    <Select
      value={
        formData.company_track_location_id
          ? `${formData.company_track_location_id}`
          : undefined
      }
      onValueChange={(e) => {
        if (formData.location_type === 'track') {
          setCompanyTrackLocationId(+e);
        } else {
          setCompanyTrackLocationId(null);
        }
      }}
    >
      <SelectTrigger id={uid + '-menu-type'}>
        <SelectValue placeholder={t('company.track-location.choose')} />
      </SelectTrigger>
      <SelectContent>
        {trackLocations &&
          trackLocations.map(({ id, name }) => (
            <SelectItem key={id} value={`${id}`}>
              {name}
            </SelectItem>
          ))}
      </SelectContent>
    </Select>
  );
};
