import { COMPANY_USER_ROLE, RESTAURANT_USER_ROLE } from '@durma-soft/gros-sdk';

import { SidebarItem } from '@/types/sidebar';
import { NavLink } from '@/components/shared/nav-link';

import { SidebarNavSubitem } from '@/layout/sidebar/sidebar-nav-subitem';

interface SidebarNavItemProps {
  item: SidebarItem;
  onCloseSidemenu: () => void;
  hideFrom?: RESTAURANT_USER_ROLE | COMPANY_USER_ROLE;
}

export const SidebarNavItem = ({
  item,
  onCloseSidemenu,
}: SidebarNavItemProps) => {
  return (
    <div>
      <NavLink
        onClick={onCloseSidemenu}
        to={item.link || ''}
        className={({ isActive }) => {
          return `flex items-center gap-3 px-3 py-3 transition-all rounded-lg cursor-pointer text-muted-foreground hover:text-primary ${
            isActive &&
            item.subitems &&
            !(item.subitems.length > 0) &&
            'text-primary'
          }`;
        }}
      >
        <span className="w-5 h-5 [&>*]:w-5 [&>*]:h-5">{item.icon}</span>
        <span>{item.label}</span>
      </NavLink>
      <ul>
        {item.subitems?.map((subitem, index) => (
          <SidebarNavSubitem
            key={index}
            subitem={subitem}
            onCloseSidemenu={onCloseSidemenu}
          />
        ))}
      </ul>
    </div>
  );
};
