import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import {
  matchPath,
  useParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { Modal } from '@/components/shared/modal';
import { Alert } from '@/components/shared/alert';
import { Button } from '@/components/shared/shadcn-ui/button';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { CreateRestaurantCompanyEmployeeForm } from '@/components/restaurant/companies/create-restaurant-company-employee-form';

export const CreateRestaurantCompanyEmployeeModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { companyId, locationId } = useParams();

  if (!companyId) {
    throw new Error('Company ID is required getting employees');
  }

  const closeModal = () => {
    if (locationId) {
      return navigate(
        `/restaurant/companies/${companyId}/locations/${locationId}/employees`,
        { replace: true },
      );
    }
    navigate(`/restaurant/companies/${companyId}/employees`);
  };

  const paths = [
    '/restaurant/companies/:companyId/employees/create',
    '/restaurant/companies/:companyId/locations/:locationId/employees/create',
  ];

  const isModalOpen = paths.find((path) => matchPath(path, pathname));

  return (
    <Modal
      isModalOpen={Boolean(isModalOpen)}
      closeModal={closeModal}
      title={t('employee.create')}
      description={t('employee.create-details')}
      className="overflow-hidden flex flex-col"
    >
      <ErrorBoundary
        fallbackRender={({ resetErrorBoundary }) => {
          return (
            <div className="flex flex-col gap-3">
              <Alert
                variant="destructive"
                title={t('common.error')}
                description={t('common.error-description')}
              />
              <Button onClick={resetErrorBoundary}>
                {t('common.try-again')}
              </Button>
            </div>
          );
        }}
      >
        <Suspense fallback={<ModalFormSkeleton numberOfSkeletons={10} />}>
          <CreateRestaurantCompanyEmployeeForm closeModal={closeModal} />
        </Suspense>
      </ErrorBoundary>
    </Modal>
  );
};
