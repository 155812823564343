import { format, isEqual } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  useAuthUserCompany,
  useGetCompanyOrdersByEmployeeReportQuery,
} from '@durma-soft/gros-sdk';

import TableSizeSyncProvider from '@/providers/TableSizeSyncProvider';

import { useDatePickerRangeState } from '@/hooks/use-date-picker-range-state';

import { PrintButton } from '@/components/shared/print-button';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import { DatePickerWithRange } from '@/components/shared/date-picker-with-range';
import { OrdersByEmployee } from '@/components/restaurant/reports/orders-by-employee';

import print from '@/lib/print/print';

import { repeat } from '@/utils/helpers';

const OrdersByEmployeeReport = () => {
  const { t } = useTranslation();

  const { from, to, setDateRange } = useDatePickerRangeState();

  const company = useAuthUserCompany();

  const {
    data: ordersByEmployee = [],
    isLoading: isLoadingOrders,
    isSuccess: isSuccessOrders,
  } = useGetCompanyOrdersByEmployeeReportQuery({
    company_id: company.id,
    start_date: from,
    end_date: to,
  });

  return (
    <>
      <Helmet>
        <title>{t('reports.orders.title')}</title>
      </Helmet>
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl">
          {t('reports.orders.title')}
        </h1>
      </div>
      <div className="flex justify-between mt-5 mb-5">
        <DatePickerWithRange
          date={{
            from: new Date(from),
            to: new Date(to),
          }}
          setDate={([from, to]) => {
            setDateRange({ from, to });
          }}
        />
        <div className="justify-start lg:justify-end flex">
          <PrintButton
            onClick={() =>
              print({
                content: (
                  <div>
                    <div>
                      <h1>{t('reports.orders.title')} </h1>
                      <p>
                        {format(from, 'dd.MM.yyyy')}
                        {!isEqual(from, to) && ` - ${format(to, 'dd.MM.yyyy')}`}
                      </p>
                    </div>
                    <TableSizeSyncProvider>
                      <div className="flex flex-col gap-10">
                        {ordersByEmployee.map(({ name, id, orders }) => (
                          <div
                            key={id}
                            className="flex flex-col gap-4 print-in-separate-page"
                          >
                            <h3 className="text-xl font-medium">{name}</h3>
                            <OrdersByEmployee orders={orders} forPrint />
                          </div>
                        ))}
                      </div>
                    </TableSizeSyncProvider>
                  </div>
                ),
              })
            }
          />
        </div>
      </div>
      <div className="flex flex-col">
        {isLoadingOrders && (
          <div className="flex flex-col gap-10">
            {repeat(5).map((index) => (
              <div key={index}>
                <Skeleton className="w-full rounded-md h-60" />
              </div>
            ))}
          </div>
        )}

        {!isLoadingOrders && ordersByEmployee.length === 0 && (
          <p className="text-center">{t('common.no-results')}</p>
        )}

        {isSuccessOrders && !isLoadingOrders && (
          <TableSizeSyncProvider>
            <div className="flex flex-col gap-10">
              {ordersByEmployee.map(({ name, id, orders }, index) => (
                <div
                  key={id}
                  className="flex flex-col gap-4 print-in-separate-page"
                >
                  <h3 className="text-xl font-medium">{name}</h3>
                  <OrdersByEmployee
                    isTableSyncReference={index === 0}
                    orders={orders}
                  />
                </div>
              ))}
            </div>
          </TableSizeSyncProvider>
        )}
      </div>
    </>
  );
};

export default OrdersByEmployeeReport;
