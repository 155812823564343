/* eslint-disable */
import { ColumnDef } from '@tanstack/react-table';
import {
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
} from '@durma-soft/gros-sdk';
import { Building2, Image, Pencil, QrCodeIcon, UsersRound } from 'lucide-react';
import { RestaurantCompany } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { Link } from '@/components/shared/link';
import { Badge } from '@/components/shared/shadcn-ui/badge';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/shared/shadcn-ui/tooltip';

import { TType } from '@/types/general';

export const columns = (t: TType): ColumnDef<RestaurantCompany>[] => [
  {
    accessorKey: 'logo',
    header: t('common.logo'),
    cell: (cellData) => {
      const { logo } = cellData.row.original;

      if (!logo) {
        return (
          <div className="inline-flex items-center justify-center w-12 h-12 border rounded-md border-border">
            <Image className="w-6 h-6" />
          </div>
        );
      }
      return (
        <img
          src={logo}
          alt=""
          width="128"
          height="128"
          className="object-cover object-center w-12 h-12 rounded-md"
        />
      );
    },
  },
  {
    accessorKey: 'name',
    header: t('common.name'),
  },
  {
    accessorKey: 'employees_count',
    header: t('employee.count'),
    cell: (cellData) => {
      return (
        <div className="w-full">
          <Link
            to={`/restaurant/companies/${cellData.row.original.id}/employees`}
          >
            <Badge className="bg-muted border-gray-300 text-gray-700 hover:bg-gray-200 transition-all duration-300">
              {cellData.row.original.employees_count}
            </Badge>
          </Link>
        </div>
      );
    },
  },
  {
    id: 'actions',
    enableHiding: false,
    cell: (cellData) => {
      // eslint-disable-next-line
      const restaurant = useAuthUserRestaurant();
      const hasEditPermission =
        restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;

      return (
        <TooltipProvider delayDuration={100}>
          <div className="flex items-center justify-center gap-5">
            {hasEditPermission && (
              <Tooltip>
                <TooltipTrigger asChild>
                  <Link
                    replace
                    to={`/restaurant/companies/edit/${cellData.row.original.id}`}
                    className="flex items-center justify-center text-gray-700"
                  >
                    <Pencil className="w-5 h-5" />
                  </Link>
                </TooltipTrigger>
                <TooltipContent>{t('company.edit-details')}</TooltipContent>
              </Tooltip>
            )}
            {/* Company locations */}
            <Tooltip>
              <TooltipTrigger>
                <Link
                  to={`/restaurant/companies/${cellData.row.original.id}/locations`}
                  className="flex items-center justify-center text-gray-700 "
                >
                  <Building2 className="w-5 h-5" />
                </Link>
              </TooltipTrigger>
              <TooltipContent>{t('company.locations')}</TooltipContent>
            </Tooltip>
            {/* Track locations */}
            <Tooltip>
              <TooltipTrigger>
                <Link
                  to={`/restaurant/companies/${cellData.row.original.id}/track-locations`}
                  className="flex items-center justify-center text-gray-700 "
                >
                  <QrCodeIcon className="w-5 h-5" />
                </Link>
              </TooltipTrigger>
              <TooltipContent>
                {t('company.track-location.title')}
              </TooltipContent>
            </Tooltip>
            {/* Company employees */}
            <Tooltip>
              <TooltipTrigger>
                <Link
                  to={`/restaurant/companies/${cellData.row.original.id}/employees`}
                  className="flex items-center justify-center text-gray-700 "
                >
                  <UsersRound className="w-5 h-5" />
                </Link>
              </TooltipTrigger>
              <TooltipContent>{t('company.employees')}</TooltipContent>
            </Tooltip>
          </div>
        </TooltipProvider>
      );
    },
  },
];
