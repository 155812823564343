import Main from '@/layout/main';
import Header from '@/layout/header';
import Sidebar from '@/layout/sidebar/sidebar';

const Layout = () => {
  return (
    <div className="flex flex-row items-stretch flex-1 w-full h-full">
      <Sidebar />
      <div className="flex flex-col flex-1 min-w-0 print:block">
        <Header />
        <div className="flex-1 flex flex-col">
          <Main />
        </div>
      </div>
    </div>
  );
};

export default Layout;
