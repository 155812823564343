import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { FormEvent, useId, useMemo, useState } from 'react';
import {
  ValidationError,
  useAuthUserRestaurant,
  useCreateIngredientMutation,
} from '@durma-soft/gros-sdk';
import {
  PreferredUnit,
  PrimaryUnit,
} from '@durma-soft/gros-sdk/dist/types/units';

import { Label } from '@/components/shared/shadcn-ui/label';
import { Input } from '@/components/shared/shadcn-ui/input';
import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/shared/shadcn-ui/select';

import { isNotEmpty } from '@/utils/helpers';
import { CreateRestaurantIngredientFormData } from '@/types/ingredients';
import {
  ingredientMeasureUnits,
  ingredientPreferredMeasureUnits,
} from '@/config/global/units';

interface CreateRestaurantIngredientFormProps {
  closeModal: () => unknown;
}

const initialFormData: CreateRestaurantIngredientFormData = {
  name: '',
  unit: 'unit',
  preferredUnit: 'unit',
};

export const CreateRestaurantIngredientForm = ({
  closeModal,
}: CreateRestaurantIngredientFormProps) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(initialFormData);

  const uid = useId();
  const restaurant = useAuthUserRestaurant();
  const createRestaurantIngredient = useCreateIngredientMutation();

  const updateUnit = (unit: PrimaryUnit) => {
    if (unit === formData.unit) return;
    setFormData((prev) => ({
      ...prev,
      unit,
      preferredUnit: unit,
    }));
  };

  const isValid = useMemo(() => {
    return formData.unit && isNotEmpty(formData.name);
  }, [formData]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;

    try {
      await createRestaurantIngredient.mutateAsync({
        restaurant_id: restaurant.id,
        name: formData.name,
        unit: formData.unit!,
        preferred_display_unit: formData.preferredUnit,
      });
      closeModal();
      setFormData(initialFormData);
      toast.success(t('ingredient.create-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('ingredient.create-error-msg'));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid gap-4 py-4">
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-name'}>{t('common.name')}</Label>
          <div className="flex items-stretch w-full">
            <Input
              required
              id={uid + '-name'}
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              placeholder={t('common.name-placeholder')}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-unit'}>
            {t('common.measure-unit.label')}
          </Label>
          <Select
            required
            value={formData.unit}
            onValueChange={(e) => updateUnit(e as PrimaryUnit)}
          >
            <SelectTrigger id={uid + '-unit'}>
              <SelectValue placeholder={t('common.measure-unit.placeholder')} />
            </SelectTrigger>
            <SelectContent>
              {ingredientMeasureUnits(t).map(({ value, name }) => (
                <SelectItem key={value} value={value}>
                  {name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-preferred-unit'}>
            {t('common.measure-unit.shown')}
          </Label>
          <Select
            value={formData.preferredUnit}
            onValueChange={(newValue) => {
              if (!newValue) return;
              setFormData({
                ...formData,
                preferredUnit: newValue as PreferredUnit,
              });
            }}
          >
            <SelectTrigger id={uid + '-preferred-unit'}>
              <SelectValue placeholder={t('common.measure-unit.placeholder')} />
            </SelectTrigger>
            <SelectContent>
              {formData.unit &&
                ingredientPreferredMeasureUnits(t)?.[formData.unit]?.map(
                  (preferredUnit) => (
                    <SelectItem
                      key={preferredUnit.value}
                      value={preferredUnit.value}
                    >
                      {preferredUnit.name}
                    </SelectItem>
                  ),
                )}
            </SelectContent>
          </Select>
        </div>
      </div>
      <DialogFooter className="mt-2">
        <Button
          type="submit"
          disabled={!isValid}
          isLoading={createRestaurantIngredient.isPending}
        >
          {t('ingredient.create')}
        </Button>
      </DialogFooter>
    </form>
  );
};
