import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import {
  useAuthUserRestaurant,
  useGetSingleRestaurantCompanyQuery,
} from '@durma-soft/gros-sdk';

import { Modal } from '@/components/shared/modal';
import { Alert } from '@/components/shared/alert';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { EditRestaurantCompanyForm } from '@/components/restaurant/companies/edit-restaurant-company-form';

export const EditRestaurantCompanyModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { companyId } = useParams();

  if (!companyId) {
    throw new Error('Company ID is required for editing employee');
  }

  const restaurant = useAuthUserRestaurant();

  const {
    data: company,
    isSuccess,
    isError,
    isLoading,
  } = useGetSingleRestaurantCompanyQuery({
    restaurant_id: restaurant.id,
    company_id: Number(companyId),
  });

  const closeModal = () => navigate('/restaurant/companies', { replace: true });

  const isModalOpen = Boolean(
    useMatch({ path: '/restaurant/companies/edit/:companyId' }),
  );

  return (
    <Modal
      key={companyId}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('company.edit')}
      description={t('company.edit-details')}
    >
      {isLoading && <ModalFormSkeleton />}
      {isError && (
        <Alert
          variant="destructive"
          title={t('common.error')}
          description={t('common.try-later')}
        />
      )}
      {isSuccess && (
        <EditRestaurantCompanyForm company={company} closeModal={closeModal} />
      )}
    </Modal>
  );
};
