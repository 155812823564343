import { toast } from 'sonner';
import { CircleUserRound } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useLogoutMutation } from '@durma-soft/gros-sdk';

import { Button } from '@/components/shared/shadcn-ui/button';
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuSeparator,
} from '@/components/shared/shadcn-ui/dropdown-menu';
import { Spinner } from '@/components/shared/spinner';

import {
  LOCAL_STORAGE_MASTER_RESTAURANT_TOKEN_KEY,
  LOCAL_STORAGE_TOKEN_KEY,
} from '@/config/global/storage';

export const UserAccount = () => {
  const { t } = useTranslation();
  const logout = useLogoutMutation();

  const handleLogout = async () => {
    await logout.mutateAsync();
    toast.success(t('auth.logout-success'));
    localStorage.clear();
  };

  const mainRestaurantToken = localStorage.getItem(
    LOCAL_STORAGE_MASTER_RESTAURANT_TOKEN_KEY,
  );

  const backToMainRestaurant = () => {
    if (mainRestaurantToken) {
      logout.mutateAsync();
      localStorage.removeItem(LOCAL_STORAGE_MASTER_RESTAURANT_TOKEN_KEY);
      localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, mainRestaurantToken);
      window.location.href = '/';
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="secondary"
          size="icon"
          className="rounded-full flex justify-center items-center"
        >
          <CircleUserRound className="w-5 h-5" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t('common.account')}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {mainRestaurantToken && (
          <DropdownMenuItem
            onClick={backToMainRestaurant}
            className="cursor-pointer"
          >
            {t('auth.back-to-main-restaurant')}
          </DropdownMenuItem>
        )}
        <DropdownMenuItem
          disabled={logout.isPending}
          onClick={handleLogout}
          className="cursor-pointer"
        >
          {logout.isPending && <Spinner size="extraSmall" />}
          {t('auth.logout')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
