import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
} from '@durma-soft/gros-sdk';
import { ShortRestaurantFood } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { DataTable } from '@/components/shared/data-table';
import { DataTableDnDContext } from '@/components/shared/data-table-dnd-context';

import { columns } from '@/config/restaurant/menu-foods';

interface FoodsTableProps {
  foods: ShortRestaurantFood[];
  onUpdateFoods: (food: ShortRestaurantFood[]) => unknown;
  onDeleteFood: (id: number) => unknown;
}

export const FoodsTable = ({
  foods,
  onUpdateFoods,
  onDeleteFood,
}: FoodsTableProps) => {
  const { t } = useTranslation();

  const restaurant = useAuthUserRestaurant();
  const hasEditPermission = restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;

  const memoizedColumns = useMemo(() => {
    return columns(t, onDeleteFood);
  }, [t, onDeleteFood]);

  const onUpdateCellValue = (
    rowIndex: number,
    columnId: string,
    value: string,
  ) => {
    const updatedData = foods?.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          [columnId]: value,
        };
      }
      return row;
    });
    onUpdateFoods(updatedData);
  };

  const dataIds = useMemo(() => {
    return foods.map((food) => `${food.id}`);
  }, [foods]);

  return (
    <DataTableDnDContext
      key={dataIds.join()}
      dataIds={dataIds}
      data={foods}
      setData={(newFoods) => onUpdateFoods(newFoods as ShortRestaurantFood[])}
    >
      <DataTable
        draggableRows
        dataIds={dataIds}
        columns={memoizedColumns}
        data={foods}
        getRowId={(row) => `${row.id}`}
        onUpdateCellValue={onUpdateCellValue}
        columnVisibility={{
          edit: hasEditPermission,
        }}
      />
    </DataTableDnDContext>
  );
};
