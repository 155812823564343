import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberOfOrdersPerEmployeeReportUser } from '@durma-soft/gros-sdk/dist/types/reports';

import { DataTable, DataTableHTML } from '@/components/shared/data-table';

import { columns } from '@/config/company/reports/number-of-company-customer-orders';
import { LocationType } from '@durma-soft/gros-sdk';

interface NumberOfCustomerCompanyOrdersProps {
  forPrint?: boolean;
  isTableSyncReference?: boolean;
  locationType: LocationType;
  users: NumberOfOrdersPerEmployeeReportUser[];
}

export const NumberOfCustomerCompanyOrders = ({
  users,
  locationType,
  forPrint = false,
  isTableSyncReference,
}: NumberOfCustomerCompanyOrdersProps) => {
  const { t } = useTranslation();

  const memoizedColumns = useMemo(
    () => columns(t, locationType),
    [t, locationType],
  );

  return (
    <>
      {!forPrint ? (
        <DataTable
          isSyncReference={isTableSyncReference}
          data={users}
          columns={memoizedColumns}
        />
      ) : (
        <DataTableHTML
          data={users}
          columns={memoizedColumns}
          columnVisibility={{ details: false }}
        />
      )}
    </>
  );
};
