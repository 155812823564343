import { useMemo, useState } from 'react';
import { format, isEqual } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ExpandedState } from '@tanstack/react-table';
import {
  useAuthUserRestaurant,
  useGetInvoicingReportQuery,
} from '@durma-soft/gros-sdk';

import { useDatePickerRangeState } from '@/hooks/use-date-picker-range-state';

import { PrintButton } from '@/components/shared/print-button';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import { DataTable, DataTableHTML } from '@/components/shared/data-table';
import { DatePickerWithRange } from '@/components/shared/date-picker-with-range';

import print from '@/lib/print/print';

import { repeat } from '@/utils/helpers';

import { columns } from '@/config/restaurant/reports/invoicing';

const InvoicingReport = () => {
  const { t } = useTranslation();

  const memoizedColumns = useMemo(() => columns(t), [t]);
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const restaurant = useAuthUserRestaurant();
  const { from, to, setDateRange } = useDatePickerRangeState();

  const { data, isLoading, isSuccess } = useGetInvoicingReportQuery({
    restaurant_id: restaurant.id,
    start_date: from,
    end_date: to,
  });

  return (
    <>
      <Helmet>
        <title>{t('reports.invoicing.title')}</title>
      </Helmet>
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl">
          {t('reports.invoicing.title')}
        </h1>
      </div>
      <div className="flex gap-5 mt-5 mb-5">
        <div>
          <DatePickerWithRange
            date={{
              from: new Date(from),
              to: new Date(to),
            }}
            setDate={([from, to]) => {
              setDateRange({ from, to });
            }}
          />
        </div>
        <div className="flex-1 justify-end flex">
          <PrintButton
            onClick={() =>
              print({
                content: (
                  <div>
                    <div>
                      <h1>{t('reports.invoicing.title')} </h1>
                      <p>
                        {format(from, 'dd.MM.yyyy')}
                        {!isEqual(from, to) && ` - ${format(to, 'dd.MM.yyyy')}`}
                      </p>
                    </div>
                    <DataTableHTML
                      columns={memoizedColumns}
                      data={data}
                      subrowsKey="locations"
                    />
                  </div>
                ),
              })
            }
          />
        </div>
      </div>
      <div>
        {isLoading && (
          <div className="flex flex-col gap-10">
            {repeat(3).map((index) => (
              <div key={index}>
                <Skeleton className="w-full rounded-md h-60" />
              </div>
            ))}
          </div>
        )}
        {isSuccess && (
          <DataTable
            columns={memoizedColumns}
            data={data}
            subrowsKey="locations"
            expanded={expanded}
            setExpanded={setExpanded}
            getRowDepthClassName={(depth) => {
              if (depth === 0) {
                return 'bg-muted hover:bg-muted';
              }
            }}
          />
        )}
      </div>
    </>
  );
};

export default InvoicingReport;
