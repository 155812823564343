import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
} from '@durma-soft/gros-sdk';

import { Modal } from '@/components/shared/modal';
import { Alert } from '@/components/shared/alert';
import { Button } from '@/components/shared/shadcn-ui/button';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { EditRestaurantCompanyEmployeeForm } from '@/components/restaurant/companies/edit-restaurant-company-employee-form';

export const EditRestaurantCompanyEmployeeModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { companyId, employeeId, locationId } = useParams();

  if (!companyId || !employeeId) {
    throw new Error('Error - company id / employee id');
  }

  const restaurant = useAuthUserRestaurant();
  const hasEditPermission = restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;

  const closeModal = () => {
    if (locationId) {
      return navigate(
        `/restaurant/companies/${companyId}/locations/${locationId}/employees`,
        { replace: true },
      );
    }
    navigate(`/restaurant/companies/${companyId}/employees`, { replace: true });
  };

  const paths = [
    '/restaurant/companies/:companyId/employees/edit/:employeeId',
    '/restaurant/companies/:companyId/locations/:locationId/employees/edit/:employeeId',
  ];

  const isModalOpen = paths.find((path) => matchPath(path, pathname));

  return (
    <Modal
      key={employeeId}
      isModalOpen={Boolean(isModalOpen)}
      closeModal={closeModal}
      title={t('employee.edit')}
      description={t('employee.edit-details')}
      className="overflow-hidden flex flex-col"
    >
      {!hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.edit-not-allowed')}
          description={t('common.edit-not-allowed-description')}
        />
      )}

      {hasEditPermission && (
        <ErrorBoundary
          fallbackRender={({ resetErrorBoundary }) => {
            return (
              <div className="flex flex-col gap-3">
                <Alert
                  variant="destructive"
                  title={t('common.error')}
                  description={t('common.error-description')}
                />
                <Button onClick={resetErrorBoundary}>
                  {t('common.try-again')}
                </Button>
              </div>
            );
          }}
        >
          <Suspense fallback={<ModalFormSkeleton numberOfSkeletons={10} />}>
            <EditRestaurantCompanyEmployeeForm onClose={closeModal} />
          </Suspense>
        </ErrorBoundary>
      )}
    </Modal>
  );
};
