import { FC, ReactNode } from 'react';
// import { useTranslation } from 'react-i18next';
import { useAuthContext } from '@durma-soft/gros-sdk';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@/components/shared/shadcn-ui/button';

export const AuthErrorMiddleware: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  // const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { isError } = useAuthContext();

  if (isError) {
    return (
      <div className="p-6">
        <p className="mb-6">Došlo je do greške prilikom prijave.</p>
        <div className="flex items-center gap-4">
          <Button onClick={() => queryClient.refetchQueries()}>
            Pokušaj ponovo
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            Odjavi se
          </Button>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};
