import {
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Check, ChevronsUpDown } from 'lucide-react';
import {
  commandScore,
  useGetAllRestaurantIngredientsQuery,
} from '@durma-soft/gros-sdk';
import { ShortRestaurantIngredient } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { Spinner } from '@/components/shared/spinner';
import { Button } from '@/components/shared/shadcn-ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/shared/shadcn-ui/popover';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/shared/shadcn-ui/command';

import { cn } from '@/utils/helpers';
import { ShortRestaurantIngredientWithQuantity } from '@/types/foods';

interface IngredientComboboxProps {
  restaurantId: number;
  selectedIngredients: ShortRestaurantIngredientWithQuantity[];
  onUpdateIngredients: (
    ingredients: ShortRestaurantIngredientWithQuantity[],
  ) => unknown;
  addPosition?: 'start' | 'end';
}

export const IngredientCombobox = ({
  restaurantId,
  selectedIngredients,
  onUpdateIngredients,
  addPosition = 'end',
}: IngredientComboboxProps) => {
  const [searchInput, setSearchInput] = useState('');
  const commandListRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const [, startTransition] = useTransition();
  const [open, setOpen] = useState(false);

  const { data: ingredients, isLoading } =
    useGetAllRestaurantIngredientsQuery(restaurantId);

  const sortedData = useMemo(() => {
    return (ingredients ? [...ingredients] : [])
      .filter((element) => commandScore(element.name, searchInput) > 0)
      .sort((a, b) => {
        return (
          commandScore(b.name, searchInput) - commandScore(a.name, searchInput)
        );
      });
  }, [ingredients, searchInput]);

  useLayoutEffect(() => {
    if (commandListRef.current) {
      commandListRef.current.scrollTop = 0;
    }
  }, [sortedData]);

  if (isLoading) return <Spinner />;

  const toggleIngredient = (ingredient: ShortRestaurantIngredient) => {
    if (selectedIngredients.some((i) => i.id === ingredient.id)) {
      onUpdateIngredients(
        selectedIngredients.filter((i) => i.id !== ingredient.id),
      );
    } else {
      addPosition === 'end'
        ? onUpdateIngredients([
            ...selectedIngredients,
            { ...ingredient, quantity: 0 },
          ])
        : onUpdateIngredients([
            { ...ingredient, quantity: 0 },
            ...selectedIngredients,
          ]);
    }
  };

  return (
    <Popover modal open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
          {selectedIngredients.length > 0
            ? `${selectedIngredients.length} ${t('common.chosen')}`
            : t('food.choose-ingredients')}
          <ChevronsUpDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command shouldFilter={false}>
          <CommandInput
            value={searchInput}
            onValueChange={(newValue) => {
              startTransition(() => {
                setSearchInput(newValue);
              });
            }}
            placeholder={t('common.search-placeholder')}
          />
          <CommandEmpty>{t('food.ingredients-not-found')}</CommandEmpty>
          <CommandGroup>
            <CommandList ref={commandListRef}>
              {ingredients &&
                sortedData.map((ingredient) => (
                  <CommandItem
                    onSelect={() => {
                      toggleIngredient(ingredient);
                    }}
                    key={ingredient.id}
                    value={`${ingredient.name}_id:${ingredient.id}`}
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        selectedIngredients.some((i) => i.id === ingredient.id)
                          ? 'opacity-100'
                          : 'opacity-0',
                      )}
                    />
                    {ingredient.name}
                  </CommandItem>
                ))}
            </CommandList>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
