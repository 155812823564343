import { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/shared/shadcn-ui/button';

import { noop } from '@/utils/helpers';

interface CustomFileInputProps {
  label?: string;
  disabled?: boolean;
  handleFile: (file: File) => void;
  hasDeleteButton?: boolean;
  deleteButtonLabel?: string;
  onDelete?: () => void;
  accept?: string;
}

export const CustomFileInput = ({
  accept = 'image/*',
  disabled = false,
  label,
  handleFile,
  hasDeleteButton = false,
  deleteButtonLabel,
  onDelete = noop,
}: CustomFileInputProps) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    if (selectedFile) handleFile(selectedFile);
    e.target.value = '';
  };

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        accept={accept}
        className="hidden"
        onChange={handleFileChange}
      />
      <div className="grid grid-cols-2 gap-2">
        <Button
          className="flex-1"
          onClick={handleUpload}
          variant="outline"
          type="button"
          disabled={disabled}
        >
          {label || t('common.choose-file')}
        </Button>
        {hasDeleteButton && (
          <Button
            className="flex-1"
            onClick={onDelete}
            variant="destructive"
            type="button"
          >
            {deleteButtonLabel || t('common.delete')}
          </Button>
        )}
      </div>
    </>
  );
};
