import { ColumnDef } from '@tanstack/react-table';

import { TType } from '@/types/general';
import { InvoicingReportRow } from '@durma-soft/gros-sdk/dist/types/reports';

export const columns = (t: TType): ColumnDef<InvoicingReportRow>[] => [
  {
    accessorKey: 'name',
    header: t('common.name'),
    cell: ({ row }) => (
      <div
        // indent-cell and bold-text-cell are classes for DataTableHTML
        className={`${
          row.depth !== 0 ? 'indent-cell pl-4' : 'bold-text-cell font-medium'
        }`}
      >
        {row.original.name}
      </div>
    ),
  },
  {
    accessorKey: 'shift_1_count',
    header: t('shifts.first'),
    cell: ({ row }) => (
      <div
        className={row.depth === 0 ? 'bold-text-cell  font-medium' : undefined}
      >
        {row.original.shift_1_count}
      </div>
    ),
    footer: ({ table }) => {
      const rows = table.getFilteredRowModel().rows;
      return rows.reduce((total, row) => {
        const rowValue = Number(row.getValue('shift_1_count'));
        return total + rowValue;
      }, 0);
    },
  },
  {
    accessorKey: 'shift_2_count',
    header: t('shifts.second'),
    cell: ({ row }) => (
      <div
        className={row.depth === 0 ? 'bold-text-cell font-medium' : undefined}
      >
        {row.original.shift_2_count}
      </div>
    ),
    footer: ({ table }) => {
      const rows = table.getFilteredRowModel().rows;
      return rows.reduce((total, row) => {
        const rowValue = Number(row.getValue('shift_2_count'));
        return total + rowValue;
      }, 0);
    },
  },
  {
    accessorKey: 'shift_3_count',
    header: t('shifts.third'),
    cell: ({ row }) => (
      <div
        className={row.depth === 0 ? 'bold-text-cell font-medium' : undefined}
      >
        {row.original.shift_3_count}
      </div>
    ),
    footer: ({ table }) => {
      const rows = table.getFilteredRowModel().rows;
      return rows.reduce((total, row) => {
        const rowValue = Number(row.getValue('shift_3_count'));
        return total + rowValue;
      }, 0);
    },
  },
  {
    accessorKey: 'total_count',
    header: t('common.total'),
    cell: ({ row }) => (
      <div
        className={row.depth === 0 ? 'bold-text-cell font-medium' : undefined}
      >
        {row.original.total_count}
      </div>
    ),
    footer: ({ table }) => {
      const rows = table.getFilteredRowModel().rows;
      return rows.reduce((total, row) => {
        const rowValue = Number(row.getValue('total_count'));
        return total + rowValue;
      }, 0);
    },
  },
];
