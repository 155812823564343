import { useTranslation } from 'react-i18next';
import { useAuthUser } from '@durma-soft/gros-sdk';

import { Link } from '@/components/shared/link';

export const SidebarHeader = () => {
  const { t } = useTranslation();
  const { restaurant, company } = useAuthUser();

  const title = restaurant
    ? restaurant.name
    : company
    ? company.name
    : t('common.title');

  return (
    <div className="flex h-14 items-center border-b px-4 lg:h-15 lg:px-6">
      <Link
        to="/"
        className="flex items-center gap-2 text-lg font-semibold truncate max-w-full"
      >
        {title}
      </Link>
    </div>
  );
};
