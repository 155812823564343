import { useTranslation } from 'react-i18next';
import {
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
  useGetSingleRestaurantIngredientQuery,
} from '@durma-soft/gros-sdk';

import { Modal } from '@/components/shared/modal';
import { Alert } from '@/components/shared/alert';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { EditRestaurantIngredientQuantityForm } from '@/components/restaurant/ingredients/edit-restaurant-ingredient-quantity-form';

interface EditRestaurantIngredientQuantityModalProps {
  date: string;
  setDate: (date: string) => unknown;
}

export const EditRestaurantIngredientQuantityModal = ({
  date,
  setDate,
}: EditRestaurantIngredientQuantityModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ingredientId } = useParams();
  const [searchParams] = useSearchParams();

  if (!ingredientId) {
    throw new Error('Ingredient ID is required for editing employee');
  }

  const restaurant = useAuthUserRestaurant();
  const hasEditPermission = restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;

  const {
    data: ingredient,
    isLoading,
    isSuccess,
    isError,
  } = useGetSingleRestaurantIngredientQuery({
    restaurant_id: restaurant.id,
    ingredient_id: Number(ingredientId),
  });

  const closeModal = () =>
    navigate(`/restaurant/ingredients?${searchParams.toString()}`, {
      replace: true,
    });
  const isModalOpen = Boolean(
    useMatch({ path: '/restaurant/ingredients/edit-quantity/:ingredientId' }),
  );
  return (
    <Modal
      key={ingredientId}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('ingredient.edit-quantity')}
    >
      {isLoading && <ModalFormSkeleton numberOfSkeletons={10} />}
      {!hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.edit-not-allowed')}
          description={t('common.edit-not-allowed-description')}
        />
      )}
      {isError && hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.error')}
          description={t('common.try-later')}
        />
      )}
      {isSuccess && hasEditPermission && (
        <EditRestaurantIngredientQuantityForm
          ingredient={ingredient}
          closeModal={closeModal}
          date={date}
          setDate={setDate}
        />
      )}
    </Modal>
  );
};
