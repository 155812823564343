import { SidebarNav } from '@/layout/sidebar/sidebar-nav';
import { SidebarHeader } from '@/layout/sidebar/sidebar-header';
import { useSidebarToggle } from '@/providers/SidebarToggleProvider';
import { cn } from '@/utils/helpers';

const Sidebar = () => {
  const [isOpen] = useSidebarToggle();
  return (
    <div
      className={cn(
        'hidden border-r bg-muted/40 duration-300 md:flex flex-col gap-2 transition-all',
        isOpen ? 'md:w-[230px] lg:w-[260px] xl:w-[320px]' : 'w-0',
      )}
    >
      <SidebarHeader />
      <SidebarNav />
    </div>
  );
};

export default Sidebar;
