import { NavLink } from '@/components/shared/nav-link';
import { SidebarSubitem } from '@/types/sidebar';

interface ISidebarNavSubitemProps {
  subitem: SidebarSubitem;
  onCloseSidemenu: () => void;
}

export const SidebarNavSubitem = ({
  subitem,
  onCloseSidemenu,
}: ISidebarNavSubitemProps) => {
  return (
    <NavLink
      onClick={onCloseSidemenu}
      to={subitem.link}
      className={({ isActive }) =>
        `flex items-center gap-3 px-8 py-1.5 transition-all rounded-lg text-muted-foreground hover:text-primary ${
          isActive && 'text-primary'
        }`
      }
    >
      <span className="w-5 h-5 [&>*]:w-5 [&>*]:h-5">{subitem.icon}</span>
      <span>{subitem.label}</span>
    </NavLink>
  );
};
