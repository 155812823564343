import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { CountryCode } from 'libphonenumber-js';
import { FormEvent, useId, useMemo, useState } from 'react';
import { Restaurant } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  asYouTypePhoneNumber,
  parsePhoneNumber,
  phoneNumberInputPlaceholder,
  useAuthUserRestaurant,
  useEditChildRestaurantMutation,
  ValidationError,
} from '@durma-soft/gros-sdk';

import { Label } from '@/components/shared/shadcn-ui/label';
import { Input } from '@/components/shared/shadcn-ui/input';
import { InputAddon } from '@/components/shared/input-addon';
import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import { CustomFileInput } from '@/components/shared/custom-file-input';
import { SelectCountryCallingCode } from '@/components/shared/select-country-code';

import { isNotEmpty, noop } from '@/utils/helpers';
import { useChangedObject } from '@/hooks/use-changed-object';

import { EditRestaurantPartnerFormData } from '@/types/partners';

interface EditRestaurantPartnerFormProps {
  partnerRestaurant: Restaurant;
  onClose?: () => unknown;
}

export const EditRestaurantPartnerForm = ({
  partnerRestaurant,
  onClose = noop,
}: EditRestaurantPartnerFormProps) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<EditRestaurantPartnerFormData>({
    name: partnerRestaurant.name,
    username_suffix: partnerRestaurant.username_suffix,
    phone_number:
      parsePhoneNumber(partnerRestaurant.phone_number)?.formatNational() ||
      partnerRestaurant.phone_number ||
      '',
    logo: partnerRestaurant.logo,
    street: partnerRestaurant.street,
    city: partnerRestaurant.city,
    zip: partnerRestaurant.zip,
    state: partnerRestaurant.state,
    country: partnerRestaurant.country,
  });

  const [countryCode, setCountryCode] = useState<CountryCode>(
    import.meta.env.VITE_DEFAULT_COUNTRY_CODE || 'BA',
  );

  const uid = useId();
  const restaurant = useAuthUserRestaurant();

  const editPartnerRestaurant = useEditChildRestaurantMutation();

  const isValid = useMemo(() => {
    return isNotEmpty(formData.name);
  }, [formData]);

  const [isChanged, changedObj] = useChangedObject(
    formData,
    partnerRestaurant,
    {
      customComparators: {
        phone_number: (a, b) => {
          if (!a && !b) return true;
          return (
            parsePhoneNumber(a as string, countryCode)?.format('E.164') === b
          );
        },
      },
    },
  );

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isChanged || !isValid) return;
    try {
      await editPartnerRestaurant.mutateAsync({
        restaurant_id: restaurant.id,
        child_restaurant_id: partnerRestaurant.id,
        ...changedObj,
        phone_number: changedObj.phone_number
          ? parsePhoneNumber(changedObj.phone_number, countryCode)?.format(
              'E.164',
            )
          : undefined,
        name: formData.name || '',
        logo:
          typeof formData.logo === 'string'
            ? undefined
            : (formData.logo as unknown as File),
      });
      onClose();
      toast.success(t('partner.edit-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('partner.edit-error.msg'));
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col flex-1 overflow-y-hidden"
    >
      <div className="flex flex-col flex-1 gap-4 p-2 overflow-y-auto">
        <div className="flex flex-col gap-4">
          {/* Name */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-name'}>{t('common.name')}</Label>
            <Input
              id={uid + '-name'}
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              placeholder={t('common.name-placeholder')}
            />
          </div>
          {/* Username suffix */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-username-suffix'}>
              {t('partner.suffix')}
            </Label>
            <div className="flex items-stretch">
              <InputAddon position="left">@</InputAddon>
              <Input
                className="flex-1 border-l-0 rounded-tl-none rounded-bl-none"
                id={uid + '-username-suffix'}
                value={formData.username_suffix}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    username_suffix: e.target.value,
                  })
                }
                placeholder={t('partner.suffix-placeholder')}
              />
            </div>
          </div>
          {/* Owner phone number */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-phone-number'}>
              {t('company.phone-number')}
            </Label>
            <div className="flex items-stretch">
              <InputAddon position="left">
                <SelectCountryCallingCode
                  value={countryCode}
                  onChange={(e) => {
                    setCountryCode(e.target.value as CountryCode);
                    setFormData({
                      ...formData,
                      phone_number: '',
                    });
                  }}
                />
              </InputAddon>
              <Input
                className="flex-1 border-l-0 rounded-tl-none rounded-bl-none"
                id={uid + '-phone-number'}
                placeholder={phoneNumberInputPlaceholder[countryCode]}
                value={formData.phone_number || ''}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    phone_number: asYouTypePhoneNumber(
                      e.target.value,
                      countryCode,
                    ),
                  })
                }
              />
            </div>
          </div>
          {/* Logo */}
          <div className="flex flex-col gap-2">
            <CustomFileInput
              label={t('common.add-logo')}
              handleFile={(logo) => setFormData({ ...formData, logo })}
              hasDeleteButton={Boolean(formData.logo)}
              onDelete={() => setFormData({ ...formData, logo: null })}
            />
            <div>
              {formData.logo && (
                <img
                  src={
                    typeof formData.logo === 'string'
                      ? formData.logo
                      : URL.createObjectURL(formData.logo)
                  }
                  height="128"
                  width="128"
                  className="object-contain object-center w-32 h-32 rounded-md"
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          {/* Street */}
          <h4 className="text-lg">{t('common.address')}</h4>
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-street'}>
              {t('common.address-details.street')}
            </Label>
            <Input
              id={uid + '-street'}
              value={formData.street || ''}
              onChange={(e) =>
                setFormData({ ...formData, street: e.target.value })
              }
              placeholder={t('common.address-details.street-placeholder')}
            />
          </div>
          {/* City */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-city'}>
              {t('common.address-details.city')}
            </Label>
            <Input
              id={uid + '-city'}
              value={formData.city || ''}
              onChange={(e) =>
                setFormData({ ...formData, city: e.target.value })
              }
              placeholder={t('common.address-details.city-placeholder')}
            />
          </div>
          {/* ZIP */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-zip'}>
              {t('common.address-details.zip')}
            </Label>
            <Input
              id={uid + '-zip'}
              value={formData.zip || ''}
              onChange={(e) =>
                setFormData({ ...formData, zip: e.target.value })
              }
              placeholder={t('common.address-details.zip-placeholder')}
            />
          </div>
          {/* State */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-state'}>
              {t('common.address-details.state')}
            </Label>
            <Input
              id={uid + '-state'}
              value={formData.state || ''}
              onChange={(e) =>
                setFormData({ ...formData, state: e.target.value })
              }
              placeholder={t('common.address-details.state-placeholder')}
            />
          </div>
          {/* Country */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-country'}>
              {t('common.address-details.country')}
            </Label>
            <Input
              id={uid + '-country'}
              value={formData.country || ''}
              onChange={(e) =>
                setFormData({ ...formData, country: e.target.value })
              }
              placeholder={t('common.address-details.country-placeholder')}
            />
          </div>
        </div>
      </div>
      <DialogFooter className="mt-2">
        <Button
          type="submit"
          isLoading={editPartnerRestaurant.isPending}
          disabled={!isChanged || !isValid}
        >
          {t('common.save-edit')}
        </Button>
      </DialogFooter>
    </form>
  );
};
