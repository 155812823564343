import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { COMPANY_USER_ROLE, useAuthUserCompany } from '@durma-soft/gros-sdk';

import { Modal } from '@/components/shared/modal';
import { Alert } from '@/components/shared/alert';
import { Button } from '@/components/shared/shadcn-ui/button';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { EditCompanyEmployeeForm } from '@/components/company/employees/edit-company-employee-form';

export const EditCompanyEmployeeModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { locationId } = useParams();

  const company = useAuthUserCompany();
  const hasEditPermission =
    company.role === COMPANY_USER_ROLE.MANAGER ||
    company.role === COMPANY_USER_ROLE.OWNER;

  const closeModal = () => {
    if (locationId) {
      return navigate(`/company/locations/${locationId}/employees`, {
        replace: true,
      });
    }
    navigate(`/company/employees`, { replace: true });
  };

  const paths = [
    '/company/employees/edit/:employeeId',
    '/company/locations/:locationId/employees/edit/:employeeId',
  ];

  const isModalOpen = paths.find((path) => matchPath(path, pathname));

  return (
    <Modal
      key={locationId}
      isModalOpen={Boolean(isModalOpen)}
      closeModal={closeModal}
      title={t('employee.edit')}
      description={t('employee.edit-details')}
      className="overflow-hidden flex flex-col"
    >
      {!hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.edit-not-allowed')}
          description={t('common.edit-not-allowed-description')}
        />
      )}

      {hasEditPermission && (
        <ErrorBoundary
          fallbackRender={({ resetErrorBoundary }) => {
            return (
              <div className="flex flex-col gap-3">
                <Alert
                  variant="destructive"
                  title={t('common.error')}
                  description={t('common.error-description')}
                />
                <Button onClick={resetErrorBoundary}>
                  {t('common.try-again')}
                </Button>
              </div>
            );
          }}
        >
          <Suspense fallback={<ModalFormSkeleton numberOfSkeletons={10} />}>
            <EditCompanyEmployeeForm onClose={closeModal} />
          </Suspense>
        </ErrorBoundary>
      )}
    </Modal>
  );
};
