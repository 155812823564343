import { toast } from 'sonner';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FileSpreadsheet } from 'lucide-react';
import { FormEvent, useId, useMemo, useState } from 'react';
import { CompanyLocation } from '@durma-soft/gros-sdk/dist/types/company';
import {
  useAuthUserRestaurant,
  useImportRestaurantCompanyEmployeesMutation,
  ValidationError,
} from '@durma-soft/gros-sdk';

import { Alert } from '@/components/shared/alert';
import { Label } from '@/components/shared/shadcn-ui/label';
import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import { CustomFileInput } from '@/components/shared/custom-file-input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/shared/shadcn-ui/select';

interface ImportCompanyEmployeesFormProps {
  locations: CompanyLocation[];
  onClose: () => void;
}

interface ImportCompanyEmployeesFormData {
  location_id: number | null;
  employees: File | null;
}

export const ImportCompanyEmployeesForm = ({
  locations,
  onClose,
}: ImportCompanyEmployeesFormProps) => {
  const uid = useId();
  const { t } = useTranslation();
  const restaurant = useAuthUserRestaurant();

  const { companyId, locationId } = useParams();

  if (!companyId) {
    throw new Error('Company ID is required getting employees');
  }

  const [formData, setFormData] = useState<ImportCompanyEmployeesFormData>({
    location_id: Number(locationId) || null,
    employees: null,
  });

  const isValid = useMemo(() => {
    return formData.location_id !== null && formData.employees !== null;
  }, [formData]);

  const importRestaurantCompanyEmployees =
    useImportRestaurantCompanyEmployeesMutation();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;
    try {
      await importRestaurantCompanyEmployees.mutateAsync({
        restaurant_id: restaurant.id,
        company_id: Number(companyId),
        company_location_id: Number(formData.location_id),
        file: formData.employees as File,
      });
      onClose();
      setFormData({
        location_id: Number(locationId) || null,
        employees: null,
      });
      toast.success(t('employee.import-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return;
      }
      toast.error(t('employee.import-error-msg'));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid gap-4 py-4">
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-company-locations'}>
            {t('employee.company-locations')}
          </Label>
          <Select
            value={
              locations.find((location) => formData.location_id === location.id)
                ?.name as string
            }
            onValueChange={(e) => {
              setFormData({
                ...formData,
                location_id: locations.find(
                  (location) => location.name === (e as string),
                )?.id as number,
              });
            }}
          >
            <SelectTrigger id={uid + '-company-location'}>
              <SelectValue
                placeholder={t('employee.company-locations-placeholder')}
              />
            </SelectTrigger>
            <SelectContent>
              {locations &&
                locations?.map((location) => (
                  <SelectItem key={location.id} value={location.name}>
                    {location.name}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex flex-col gap-3.5">
          <CustomFileInput
            accept="file"
            label={t('common.choose-file')}
            hasDeleteButton={Boolean(formData.employees)}
            onDelete={() => {
              setFormData({ ...formData, employees: null });
              importRestaurantCompanyEmployees.reset();
            }}
            handleFile={(file) => {
              if (file) {
                setFormData({ ...formData, employees: file });
              }
            }}
          />
          <div>
            {formData.employees && (
              <div className="pl-2 flex gap-2 items-center">
                <FileSpreadsheet />
                <p>{formData.employees.name}</p>
              </div>
            )}
          </div>
        </div>
        {importRestaurantCompanyEmployees.isError && (
          <Alert
            title={t('common.error')}
            description={importRestaurantCompanyEmployees.error.message}
            variant="destructive"
          />
        )}
      </div>
      <DialogFooter className="mt-2">
        <Button
          type="submit"
          disabled={!isValid}
          isLoading={importRestaurantCompanyEmployees.isPending}
        >
          {t('employee.import-btn')}
        </Button>
      </DialogFooter>
    </form>
  );
};
