import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { CountryCode } from 'libphonenumber-js';
import { FormEvent, useId, useMemo, useState } from 'react';
import {
  ValidationError,
  useEditRestaurantEmployeeMutation,
  asYouTypePhoneNumber,
  parsePhoneNumber,
  phoneNumberInputPlaceholder,
  useAuthUserRestaurant,
  RESTAURANT_USER_ROLE,
} from '@durma-soft/gros-sdk';
import { RestaurantEmployee } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { isNotEmpty, noop } from '@/utils/helpers';

import { Label } from '@/components/shared/shadcn-ui/label';
import { Input } from '@/components/shared/shadcn-ui/input';
import { InputAddon } from '@/components/shared/input-addon';
import { SelectRole } from '@/components/shared/select-role';
import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import { SelectCountryCallingCode } from '@/components/shared/select-country-code';

import { EditEmployeeFormData } from '@/types/employees';
import { useChangedObject } from '@/hooks/use-changed-object';
import { restaurantRoles } from '@/config/global/roles';
import { EyeIcon, EyeOff } from 'lucide-react';

interface EditRestaurantEmployeeFormProps {
  employee: RestaurantEmployee;
  onClose?: () => unknown;
  onSubmit?: (employee: RestaurantEmployee) => unknown;
}

export const EditRestaurantEmployeeForm = ({
  employee,
  onSubmit = noop,
  onClose = noop,
}: EditRestaurantEmployeeFormProps) => {
  const { t } = useTranslation();
  const restaurant = useAuthUserRestaurant();

  const [countryCode, setCountryCode] = useState<CountryCode>(
    parsePhoneNumber(employee.phone_number)?.country ||
      import.meta.env.VITE_DEFAULT_COUNTRY_CODE ||
      'BA',
  );

  const [formData, setFormData] = useState<EditEmployeeFormData>({
    email: employee.email,
    first_name: employee.first_name,
    last_name: employee.last_name,
    phone_number:
      parsePhoneNumber(employee.phone_number)?.formatNational() ||
      employee.phone_number ||
      '',
    username: employee.username.replace('@' + restaurant.username_suffix, ''),
    role: `${employee.role}`,
    password: '',
    password_confirmation: '',
  });

  const uid = useId();
  const editRestaurantEmployee = useEditRestaurantEmployeeMutation();

  const [isChanged, changedObj] = useChangedObject(formData, employee, {
    customComparators: {
      username: (a, b) => a + '@' + restaurant.username_suffix === b,
      password: (a) => a !== undefined && a === '',
      phone_number: (a, b) => {
        if (!a && !b) return true;
        return parsePhoneNumber(a, countryCode)?.format('E.164') === b;
      },
    },
    keysToIgnore: ['password_confirmation'],
  });

  const [showPassword, setShowPassword] = useState(false);

  const doesPasswordsMatch = useMemo(() => {
    return formData.password === formData.password_confirmation;
  }, [formData.password, formData.password_confirmation]);

  const isValid = useMemo(() => {
    return (
      isNotEmpty(formData.first_name) &&
      isNotEmpty(formData.last_name) &&
      isNotEmpty(formData.username) &&
      doesPasswordsMatch
    );
  }, [formData, doesPasswordsMatch]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isChanged || !isValid) return;
    try {
      const data = await editRestaurantEmployee.mutateAsync({
        restaurant_id: restaurant.id,
        employee_id: employee.id,
        ...changedObj,
        username: changedObj.username
          ? `${formData.username}@${restaurant.username_suffix}`
          : undefined,
        phone_number:
          parsePhoneNumber(formData.phone_number, countryCode)?.format(
            'E.164',
          ) || null,
        role: Number(formData.role),
        password: formData.password || undefined,
        password_confirmation: formData.password_confirmation || undefined,
      });
      onSubmit(data);
      onClose();
      toast.success(t('restaurant-employee.edit-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('restaurant-employee.edit-error-msg'));
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col flex-1 gap-6 overflow-hidden"
    >
      <div className="flex flex-col flex-1 gap-4 p-2 overflow-y-auto">
        {/* Username */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-username'}>{t('common.username')}</Label>
          <div className="flex items-stretch w-full">
            <Input
              id={uid + '-username'}
              value={formData.username}
              onChange={(e) =>
                setFormData({ ...formData, username: e.target.value })
              }
              className="flex-1 lowercase border-r-0 rounded-r-none"
              placeholder="marko.markovic"
            />
            <div className="flex items-center justify-center px-3 border border-input rounded-r-md">
              <span className="text-sm text-gray-700 select-none">
                @{restaurant.username_suffix}
              </span>
            </div>
          </div>
        </div>
        {/* Email */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-email'}>{t('common.email')}</Label>
          <Input
            id={uid + '-email'}
            type="email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-firstName'}>{t('common.first-name')}</Label>
          <Input
            id={uid + '-firstName'}
            value={formData.first_name}
            onChange={(e) =>
              setFormData({ ...formData, first_name: e.target.value })
            }
          />
        </div>
        {/* Last name */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-lastName'}>{t('common.last-name')}</Label>
          <Input
            id={uid + '-lastName'}
            value={formData.last_name}
            onChange={(e) =>
              setFormData({ ...formData, last_name: e.target.value })
            }
          />
        </div>
        {/* Phone number */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-phone-number'}>
            {t('common.phone-number')}
          </Label>
          <div className="flex items-stretch">
            <InputAddon position="left">
              <SelectCountryCallingCode
                value={countryCode}
                onChange={(e) => {
                  setCountryCode(e.target.value as CountryCode);
                  setFormData({
                    ...formData,
                    phone_number: '',
                  });
                }}
              />
            </InputAddon>
            <Input
              className="flex-1 border-l-0 rounded-tl-none rounded-bl-none"
              id={uid + '-phone-number'}
              value={formData.phone_number}
              placeholder={phoneNumberInputPlaceholder[countryCode]}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  phone_number: asYouTypePhoneNumber(
                    e.target.value,
                    countryCode,
                  ),
                })
              }
            />
          </div>
        </div>
        {/* Role */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-role'}>{t('roles.title')}</Label>
          <SelectRole
            disabled={restaurant.role !== RESTAURANT_USER_ROLE.OWNER}
            roles={restaurantRoles(t)}
            placeholder={t('roles.choose')}
            value={formData.role || ''}
            onValueChange={(role) => {
              setFormData((prevData) => {
                return {
                  ...prevData,
                  role: role as `${RESTAURANT_USER_ROLE}`,
                };
              });
            }}
          />
        </div>
        {/* Password */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-password'}>{t('common.password')}</Label>
          <div className="flex items-stretch">
            <Input
              error={
                formData.password_confirmation !== '' &&
                !doesPasswordsMatch &&
                t('common.passwords-must-match')
              }
              id={uid + '-password'}
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
              className={`flex-1 border-r-0 rounded-tr-none rounded-br-none ${
                formData.password_confirmation !== '' &&
                !doesPasswordsMatch &&
                'border-r'
              } `}
            />
            <InputAddon
              position="right"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <EyeIcon className="w-4 h-4" />
              ) : (
                <EyeOff className="w-4 h-4" />
              )}
            </InputAddon>
          </div>
        </div>
        {/* Password confirmation */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-password-confirmation'}>
            {t('common.password-confirmation')}
          </Label>
          <div className="flex items-stretch">
            <Input
              error={
                formData.password_confirmation !== '' &&
                !doesPasswordsMatch &&
                t('common.passwords-must-match')
              }
              id={uid + '-password-confirmation'}
              type={showPassword ? 'text' : 'password'}
              value={formData.password_confirmation}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  password_confirmation: e.target.value,
                })
              }
              className={`flex-1 border-r-0 rounded-tr-none rounded-br-none ${
                formData.password_confirmation !== '' &&
                !doesPasswordsMatch &&
                'border-r'
              } `}
            />
            <InputAddon
              position="right"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <EyeIcon className="w-4 h-4" />
              ) : (
                <EyeOff className="w-4 h-4" />
              )}
            </InputAddon>
          </div>
        </div>
      </div>
      <DialogFooter className="mt-2">
        <Button
          type="submit"
          isLoading={editRestaurantEmployee.isPending}
          disabled={!isChanged || !isValid}
        >
          {t('common.save-edit')}
        </Button>
      </DialogFooter>
    </form>
  );
};
