import { useMemo, useState } from 'react';
import { format, isEqual } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  useAuthUserRestaurant,
  useGetAllRestaurantCompaniesQuery,
  useGetFoodReportQuery,
} from '@durma-soft/gros-sdk';
import { ShortRestaurantLocation } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { useDatePickerRangeState } from '@/hooks/use-date-picker-range-state';

import { PrintButton } from '@/components/shared/print-button';
import { DataTable, DataTableHTML } from '@/components/shared/data-table';
import { DatePickerWithRange } from '@/components/shared/date-picker-with-range';
import { SelectCompanyLocations } from '@/components/shared/select-company-location';

import print from '@/lib/print/print';

import { columns } from '@/config/restaurant/reports/foods';

const FoodsReport = () => {
  const { t } = useTranslation();
  const memoizedColumns = useMemo(() => columns(t), [t]);

  const { from, to, setDateRange } = useDatePickerRangeState();

  const restaurant = useAuthUserRestaurant();

  const [companyLocations, setCompanyLocations] = useState<
    ShortRestaurantLocation[]
  >([]);

  const {
    data: companies,
    isLoading: isLoadingCompanies,
    isSuccess: isSuccessCompanies,
  } = useGetAllRestaurantCompaniesQuery(restaurant.id);

  const { data, isLoading } = useGetFoodReportQuery({
    restaurant_id: restaurant.id,
    start_date: from,
    end_date: to,
    company_location_ids: companyLocations.length
      ? companyLocations.map(({ id }) => id)
      : [],
  });

  return (
    <>
      <Helmet>
        <title>{t('reports.foods.title')}</title>
      </Helmet>
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl">
          {t('reports.foods.title')}
        </h1>
      </div>
      <div className="flex flex-col gap-3 my-5 lg:flex-row ">
        <div className="flex-1 flex flex-col gap-3 lg:flex-row ">
          <div className="self-start">
            <DatePickerWithRange
              date={{ from: new Date(from), to: new Date(to) }}
              setDate={([from, to]) => setDateRange({ from, to })}
            />
          </div>
          <div>
            <SelectCompanyLocations
              selectAllByDefault
              companies={isSuccessCompanies ? companies : []}
              isLoading={isLoadingCompanies}
              selectedCompanyLocations={companyLocations}
              onUpdateCompanyLocations={setCompanyLocations}
            />
          </div>
        </div>
        <div>
          <PrintButton
            onClick={() =>
              print({
                content: (
                  <div>
                    <div>
                      <h1>{t('reports.foods.title')} </h1>
                      <p>
                        {format(from, 'dd.MM.yyyy')}
                        {!isEqual(from, to) && ` - ${format(to, 'dd.MM.yyyy')}`}
                      </p>
                    </div>
                    <DataTableHTML columns={memoizedColumns} data={data} />
                  </div>
                ),
              })
            }
          />
        </div>
      </div>
      <div className="flex flex-col gap-3">
        {companyLocations.length === 0 && (
          <p className="self-center">{t('reports.orders.choose-location')}</p>
        )}

        {companyLocations.length !== 0 && (
          <DataTable
            columns={memoizedColumns}
            data={data}
            isLoading={isLoading}
          />
        )}
      </div>
    </>
  );
};

export default FoodsReport;
