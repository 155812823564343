import { useState } from 'react';
import { MenuIcon } from 'lucide-react';
import {
  Locale,
  useAuthUser,
  useUpdateCurrentUserLocaleMutation,
} from '@durma-soft/gros-sdk';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/shared/shadcn-ui/button';
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from '@/components/shared/shadcn-ui/sheet';

import { SidebarNav } from '@/layout/sidebar/sidebar-nav';
import { SelectLanguage } from '@/components/shared/select-language';

import { UserAccount } from '@/layout/header/user-account';

export const SidebarMobile = () => {
  const { user } = useAuthUser();
  const { t, i18n } = useTranslation();
  const updateUserLocaleMutation = useUpdateCurrentUserLocaleMutation();

  const [isSidemenuOpen, setIsSidemenuOpen] = useState(false);

  return (
    <div className="flex-1 md:hidden flex items-center justify-between">
      <Sheet open={isSidemenuOpen} onOpenChange={setIsSidemenuOpen}>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon" className="shrink-0 md:hidden">
            <MenuIcon className="w-5 h-5 cursor-pointer" />
          </Button>
        </SheetTrigger>
        <SheetContent
          side="left"
          className="flex flex-col overflow-y-auto py-4 px-0"
        >
          <SidebarNav onCloseSidemenu={() => setIsSidemenuOpen(false)} />
        </SheetContent>
      </Sheet>
      <div className="flex items-center gap-2">
        <div>
          <SelectLanguage
            value={user.locale}
            isLoading={updateUserLocaleMutation.isPending}
            placeholder={t(`languages.${user.locale as Locale}`)}
            onValueChange={(lang) => {
              i18n.changeLanguage(lang);
              updateUserLocaleMutation.mutate({ locale: lang as Locale });
            }}
          />
        </div>
        <div>
          <UserAccount />
        </div>
      </div>
    </div>
  );
};
