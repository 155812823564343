import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Check, ChevronsUpDown } from 'lucide-react';
import {
  ShortRestaurantCompanyWithLocations,
  ShortRestaurantLocation,
} from '@durma-soft/gros-sdk/dist/types/restaurants';

import { Spinner } from '@/components/shared/spinner';
import { Button } from '@/components/shared/shadcn-ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/shared/shadcn-ui/popover';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/shared/shadcn-ui/command';

import { cn } from '@/utils/helpers';

interface SelectCompanyLocationsProps {
  companies: ShortRestaurantCompanyWithLocations[];
  isLoading?: boolean;
  selectAllByDefault?: boolean;
  selectedCompanyLocations: ShortRestaurantLocation[];
  onUpdateCompanyLocations: (
    companyLocations: ShortRestaurantLocation[],
  ) => unknown;
}

export const SelectCompanyLocations = ({
  companies = [],
  isLoading = false,
  selectAllByDefault = false,
  selectedCompanyLocations,
  onUpdateCompanyLocations,
}: SelectCompanyLocationsProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const { allLocations, areAllSelected } = useMemo(() => {
    const allLocations = companies.flatMap((company) => company.locations);
    const areAllSelected = allLocations.every((location) =>
      selectedCompanyLocations.some((i) => i.id === location.id),
    );
    return { allLocations, areAllSelected };
  }, [companies, selectedCompanyLocations]);

  useEffect(() => {
    if (
      companies?.length &&
      selectAllByDefault &&
      selectedCompanyLocations.length === 0
    ) {
      onUpdateCompanyLocations(allLocations);
    }
    //eslint-disable-next-line
  }, [companies, selectAllByDefault]);

  const toggleCompanyLocations = (location: ShortRestaurantLocation) => {
    if (selectedCompanyLocations.some((i) => i.id === location.id)) {
      onUpdateCompanyLocations(
        selectedCompanyLocations.filter((i) => i.id !== location.id),
      );
    } else {
      onUpdateCompanyLocations([...selectedCompanyLocations, { ...location }]);
    }
  };

  const toggleAllLocations = () => {
    if (areAllSelected) {
      onUpdateCompanyLocations([]);
    } else {
      onUpdateCompanyLocations(allLocations);
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <Popover modal open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild className="w-[200px]">
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between font-normal"
        >
          {areAllSelected
            ? t('company.all-branches')
            : selectedCompanyLocations.length > 0
            ? `${selectedCompanyLocations.length} ${t('common.chosen')}`
            : t('company.choose-branches')}
          <ChevronsUpDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[320px] p-0">
        <Command>
          <CommandInput placeholder={t('common.search-placeholder')} />
          <CommandEmpty>{t('company-locations.not-found')}</CommandEmpty>
          <CommandList>
            {companies &&
              companies.map((company) => (
                <CommandGroup heading={company.name} key={company.id}>
                  {company.locations.map((location) => (
                    <CommandItem
                      onSelect={() => {
                        toggleCompanyLocations(location);
                      }}
                      key={location.id}
                      value={String(location.name) + String(location.id)}
                    >
                      <Check
                        className={cn(
                          'mr-2 h-4 w-4',
                          selectedCompanyLocations.some(
                            (i) => i.id === location.id,
                          )
                            ? 'opacity-100'
                            : 'opacity-0',
                        )}
                      />
                      {location.name}
                    </CommandItem>
                  ))}
                </CommandGroup>
              ))}
          </CommandList>
          <CommandItem
            onSelect={toggleAllLocations}
            className="border-t-[2px] border-gray-100 font-medium py-2"
          >
            <Check
              className={cn(
                'mr-2 h-4 w-4',
                areAllSelected ? 'opacity-100' : 'opacity-0',
              )}
            />
            {t('company.all-branches')}
          </CommandItem>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
