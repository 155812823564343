import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useAuthUserCompany } from '@durma-soft/gros-sdk';
import { useGetCompanyTrackLocationsQuery } from '@durma-soft/gros-sdk/dist/hooks/queries';

import { DataTable } from '@/components/shared/data-table';
import { buttonVariants } from '@/components/shared/shadcn-ui/button';

import { columns } from '@/config/company/track-locations';
import { CreateCompanyTrackLocationModal } from '@/components/company/track-locations/create-company-track-location-modal';
import { EditCompanyTrackLocationModal } from '@/components/company/track-locations/edit-company-track-location-modal';

const CompanyTrackLocations = () => {
  const { t } = useTranslation();
  const { trackLocationId } = useParams();
  const company = useAuthUserCompany();

  const { data, isLoading } = useGetCompanyTrackLocationsQuery({
    company_id: company.id,
  });

  const memoizedColumns = useMemo(() => {
    return columns(t);
  }, [t]);

  return (
    <>
      <Helmet>
        <title>{t('company.track-location.title')}</title>
      </Helmet>
      <div>
        <div className="flex items-center">
          <h1 className="text-lg font-semibold md:text-2xl">
            {t('company.track-location.title')}
          </h1>
        </div>
        <div className="flex flex-col gap-3">
          <div className="self-end">
            <Link
              to={`/company/track-locations/create`}
              className={buttonVariants({ variant: 'default' })}
            >
              {t('company.track-location.create')}
            </Link>
          </div>
          <DataTable
            columns={memoizedColumns}
            data={data}
            isLoading={isLoading}
          />
        </div>
      </div>
      <CreateCompanyTrackLocationModal />
      {Boolean(trackLocationId) && (
        <EditCompanyTrackLocationModal key={trackLocationId} />
      )}
    </>
  );
};

export default CompanyTrackLocations;
