import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import {
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
} from '@durma-soft/gros-sdk';

import { Modal } from '@/components/shared/modal';
import { Alert } from '@/components/shared/alert';
import { Button } from '@/components/shared/shadcn-ui/button';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { EditRestaurantCompanyLocationForm } from '@/components/restaurant/companies/edit-restaurant-company-location-form';

export const EditRestaurantCompanyLocationModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { companyId, locationId } = useParams();

  if (!companyId || !locationId) {
    throw new Error(
      'Company ID and location ID are required for editing location',
    );
  }

  const restaurant = useAuthUserRestaurant();
  const hasEditPermission = restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;

  const closeModal = () =>
    navigate(`/restaurant/companies/${companyId}/locations`, { replace: true });

  const isModalOpen = Boolean(
    useMatch({
      path: '/restaurant/companies/:comapnyId/locations/edit/:locationId',
    }),
  );

  return (
    <Modal
      key={locationId + companyId}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('company-locations.edit')}
      description={t('company-locations.edit-details')}
    >
      {!hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.edit-not-allowed')}
          description={t('common.edit-not-allowed-description')}
        />
      )}

      {hasEditPermission && (
        <ErrorBoundary
          fallbackRender={({ resetErrorBoundary }) => {
            return (
              <div className="flex flex-col gap-3">
                <Alert
                  variant="destructive"
                  title={t('common.error')}
                  description={t('common.error-description')}
                />
                <Button onClick={resetErrorBoundary}>
                  {t('common.try-again')}
                </Button>
              </div>
            );
          }}
        >
          <Suspense fallback={<ModalFormSkeleton numberOfSkeletons={5} />}>
            <EditRestaurantCompanyLocationForm
              closeModal={closeModal}
              companyId={companyId}
              locationId={locationId}
            />
          </Suspense>
        </ErrorBoundary>
      )}
    </Modal>
  );
};
