import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import { Modal } from '@/components/shared/modal';
import { CreateRestaurantCompanyTrackLocationForm } from '@/components/restaurant/companies/create-restaurant-company-track-location-form';

export const CreateRestaurantCompanyTrackLocationModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { companyId } = useParams();

  if (!companyId) {
    throw new Error('Company ID is required getting track locations');
  }

  const closeModal = () =>
    navigate(`/restaurant/companies/${companyId}/track-locations`, {
      replace: true,
    });

  const isModalOpen = Boolean(
    useMatch({
      path: '/restaurant/companies/:companyId/track-locations/create',
    }),
  );

  return (
    <Modal
      isModalOpen={Boolean(isModalOpen)}
      closeModal={closeModal}
      title={t('company.track-location.create')}
      description={t('company.track-location.create-details')}
      className="overflow-hidden flex flex-col"
    >
      <CreateRestaurantCompanyTrackLocationForm closeModal={closeModal} />
    </Modal>
  );
};
