import { useTranslation } from 'react-i18next';
import {
  useAuthUserRestaurant,
  useGetRestaurantCompanyLocationsQuery,
} from '@durma-soft/gros-sdk';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { Modal } from '@/components/shared/modal';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { ImportCompanyEmployeesForm } from '@/components/restaurant/companies/import-company-employees-form';

export const ImportCompanyEmployeesModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { companyId, locationId } = useParams();

  if (!companyId) {
    throw new Error('Company ID is required getting employees');
  }

  const restaurant = useAuthUserRestaurant();

  const {
    data: locations,
    isSuccess,
    isLoading,
  } = useGetRestaurantCompanyLocationsQuery({
    restaurant_id: restaurant.id,
    company_id: Number(companyId),
  });

  const closeModal = () => {
    if (locationId) {
      return navigate(
        `/restaurant/companies/${companyId}/locations/${locationId}/employees`,
        { replace: true },
      );
    }
    navigate(`/restaurant/companies/${companyId}/employees`);
  };

  const paths = [
    '/restaurant/companies/:companyId/employees/import',
    '/restaurant/companies/:companyId/locations/:locationId/employees/import',
  ];

  const isModalOpen = paths.find((path) => matchPath(path, pathname));

  return (
    <Modal
      isModalOpen={Boolean(isModalOpen)}
      closeModal={closeModal}
      title={t('employee.import')}
    >
      {isLoading && <ModalFormSkeleton numberOfSkeletons={3} />}
      {isSuccess && (
        <ImportCompanyEmployeesForm
          locations={locations}
          onClose={closeModal}
        />
      )}
    </Modal>
  );
};
