import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LocationType } from '@durma-soft/gros-sdk';
import { NumberOfOrdersPerEmployeeReportUser } from '@durma-soft/gros-sdk/dist/types/reports';

import { DataTable, DataTableHTML } from '@/components/shared/data-table';

import { columns } from '@/config/restaurant/reports/number-of-customer-orders';

interface NumberOfCustomerOrdersProps {
  users: NumberOfOrdersPerEmployeeReportUser[];
  locationType: LocationType;
  forPrint?: boolean;
  isTableSyncReference?: boolean;
}

export const NumberOfCustomerOrders = ({
  users,
  locationType,
  forPrint = false,
  isTableSyncReference,
}: NumberOfCustomerOrdersProps) => {
  const { t } = useTranslation();

  const memoizedColumns = useMemo(
    () => columns(t, locationType),
    [t, locationType],
  );

  return (
    <div>
      {!forPrint ? (
        <DataTable
          isSyncReference={isTableSyncReference}
          data={users}
          columns={memoizedColumns}
        />
      ) : (
        <DataTableHTML
          data={users}
          columns={memoizedColumns}
          columnVisibility={{ details: false }}
        />
      )}
    </div>
  );
};
