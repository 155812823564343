import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import {
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
  useGetSingleFoodQuery,
} from '@durma-soft/gros-sdk';

import { Modal } from '@/components/shared/modal';
import { Alert } from '@/components/shared/alert';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { EditRestaurantFoodForm } from '@/components/restaurant/foods/edit-restaurant-food-form';

export const EditRestaurantFoodModal = () => {
  const { t } = useTranslation();
  const { foodId } = useParams();
  const navigate = useNavigate();

  if (!foodId) {
    throw new Error('FoodId is required for editing employee');
  }

  const restaurant = useAuthUserRestaurant();
  const hasEditPermission = restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;

  const {
    data: food,
    isSuccess,
    isError,
    isLoading,
  } = useGetSingleFoodQuery({
    restaurant_id: restaurant.id,
    food_id: Number(foodId),
  });

  const closeModal = () => navigate('/restaurant/foods', { replace: true });

  const isModalOpen = Boolean(
    useMatch({ path: '/restaurant/foods/edit/:foodId' }),
  );

  return (
    <Modal
      key={foodId}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('food.edit')}
      description={t('food.edit-details')}
      className="overflow-hidden flex flex-col"
    >
      {isLoading && <ModalFormSkeleton />}
      {!hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.edit-not-allowed')}
          description={t('common.edit-not-allowed-description')}
        />
      )}
      {isError && hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.error')}
          description={t('common.try-later')}
        />
      )}
      {isSuccess && hasEditPermission && (
        <EditRestaurantFoodForm food={food} closeModal={closeModal} />
      )}
    </Modal>
  );
};
