import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import { Modal } from '@/components/shared/modal';
import { Alert } from '@/components/shared/alert';
import { Button } from '@/components/shared/shadcn-ui/button';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { CreateRestaurantCompanyLocationForm } from '@/components/restaurant/companies/create-restaurant-company-location-form';

export const CreateRestaurantCompanyLocationModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { companyId } = useParams();

  if (!companyId) {
    throw new Error('Company ID is required getting employees');
  }
  const closeModal = () => {
    navigate(`/restaurant/companies/${companyId}/locations`, { replace: true });
  };

  const isModalOpen = Boolean(
    useMatch({ path: '/restaurant/companies/:companyId/locations/create' }),
  );

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('company-locations.create')}
      description={t('company-locations.create-details')}
    >
      <ErrorBoundary
        fallbackRender={({ resetErrorBoundary }) => {
          return (
            <div className="flex flex-col gap-3">
              <Alert
                variant="destructive"
                title={t('common.error')}
                description={t('common.error-description')}
              />
              <Button onClick={resetErrorBoundary}>
                {t('common.try-again')}
              </Button>
            </div>
          );
        }}
      >
        <Suspense fallback={<ModalFormSkeleton numberOfSkeletons={10} />}>
          <CreateRestaurantCompanyLocationForm closeModal={closeModal} />
        </Suspense>
      </ErrorBoundary>
    </Modal>
  );
};
