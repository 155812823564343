export const countryCodes = [
  {
    code: '+387',
    country: 'BA',
    content: '🇧🇦  +387',
  },
  {
    code: '+381',
    country: 'RS',
    content: '🇷🇸  +381',
  },
  {
    code: '+385',
    country: 'HR',
    content: '🇭🇷  +385',
  },
  {
    code: '+386',
    country: 'SI',
    content: '🇸🇮 +386',
  },
];
