import { convertUnits, Unit } from '@durma-soft/gros-sdk';
import { RestaurantFoodWithIngredients } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { TType } from '@/types/general';

import print from './print';

const printFoodWithIngredients = (
  printWindow: Window,
  data: RestaurantFoodWithIngredients,
  t: TType,
) => {
  print({
    printWindow,
    content: (
      <div>
        <h1>{data.name}</h1>
        <ul>
          {data.ingredients.map((ingredient) => (
            <li key={ingredient.id}>
              <span>{ingredient.name}</span>
              <span> - </span>
              <span>
                {convertUnits({
                  from: ingredient.unit as Unit,
                  to:
                    (ingredient.preferred_display_unit as Unit) ||
                    (ingredient.unit as Unit),
                  quantity: ingredient.quantity,
                })}{' '}
                {t(
                  `common.measure-unit-short.${
                    ingredient.preferred_display_unit || ingredient.unit
                  }`,
                )}
              </span>
            </li>
          ))}
        </ul>
      </div>
    ),
    title: data.name,
  });
};

export default printFoodWithIngredients;
