import { Alert } from '@/components/shared/alert';
import { Modal } from '@/components/shared/modal';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { COMPANY_USER_ROLE, useAuthUserCompany } from '@durma-soft/gros-sdk';
import { useGetSingleCompanyTrackLocationQuery } from '@durma-soft/gros-sdk/dist/hooks/queries';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { EditCompanyTrackLocationForm } from './edit-company-track-location-form';

export const EditCompanyTrackLocationModal = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { trackLocationId } = useParams();

  if (!trackLocationId) {
    throw new Error(
      'Track location ID is required for editing its information!',
    );
  }

  const company = useAuthUserCompany();
  const hasEditPermission =
    company.role !== COMPANY_USER_ROLE.EMPLOYEE &&
    company.role !== COMPANY_USER_ROLE.TRACK_EMPLOYEE;

  const {
    data: trackLocation,
    isSuccess,
    isError,
    isLoading,
  } = useGetSingleCompanyTrackLocationQuery({
    company_id: company.id,
    track_location_id: +trackLocationId,
  });

  const closeModal = () =>
    navigate(`/company/track-locations`, { replace: true });

  const isModalOpen = Boolean(
    useMatch({
      path: '/company/track-locations/edit/:trackLocationId',
    }),
  );

  return (
    <Modal
      key={trackLocationId}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('company.track-location.edit')}
      description={t('company.track-location.edit-details')}
    >
      {isLoading && <ModalFormSkeleton />}
      {!hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.edit-not-allowed')}
          description={t('common.edit-not-allowed-description')}
        />
      )}
      {isError && hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.error')}
          description={t('common.try-later')}
        />
      )}
      {isSuccess && hasEditPermission && (
        <EditCompanyTrackLocationForm
          trackLocation={trackLocation}
          closeModal={closeModal}
        />
      )}
    </Modal>
  );
};
