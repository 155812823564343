import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { CountryCode } from 'libphonenumber-js';
import { FormEvent, useMemo, useState } from 'react';
import {
  parsePhoneNumber,
  useAuthUserRestaurant,
  useCreateChildRestaurantMutation,
  ValidationError,
} from '@durma-soft/gros-sdk';

import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import { PartnerOwner } from '@/components/restaurant/partners/partner-owner';
import { PartnerDetails } from '@/components/restaurant/partners/partner-details';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/shared/shadcn-ui/tabs';

import { RestaurantPartnerFormData } from '@/types/partners';
import { isNotEmpty } from '@/utils/helpers';

const initialFormData: RestaurantPartnerFormData = {
  name: '',
  username_suffix: '',
  logo: null,
  street: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  phone_number: '',
  username: '',
  first_name: '',
  last_name: '',
  owner_phone_number: '',
  email: '',
  password: '',
  password_confirmation: '',
};

interface CreateRestaurantPartnerFormProps {
  closeModal: () => unknown;
}

export const CreateRestaurantPartnerForm = ({
  closeModal,
}: CreateRestaurantPartnerFormProps) => {
  const { t } = useTranslation();
  const [formData, setFormData] =
    useState<RestaurantPartnerFormData>(initialFormData);

  const [countryCode, setCountryCode] = useState<CountryCode>(
    import.meta.env.VITE_DEFAULT_COUNTRY_CODE || 'BA',
  );

  const [ownerCountryCode, setOwnerCountryCode] = useState<CountryCode>(
    import.meta.env.VITE_DEFAULT_COUNTRY_CODE || 'BA',
  );

  const restaurant = useAuthUserRestaurant();
  const createRestaurantPartner = useCreateChildRestaurantMutation();

  const doesPasswordsMatch = useMemo(() => {
    return formData.password === formData.password_confirmation;
  }, [formData.password, formData.password_confirmation]);

  const isValid = useMemo(() => {
    return (
      isNotEmpty(formData.first_name) &&
      isNotEmpty(formData.last_name) &&
      isNotEmpty(formData.username) &&
      isNotEmpty(formData.password) &&
      doesPasswordsMatch
    );
  }, [formData, doesPasswordsMatch]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;
    try {
      await createRestaurantPartner.mutateAsync({
        restaurant_id: restaurant.id,
        name: formData.name,
        username_suffix: formData.username_suffix,
        logo:
          typeof formData.logo === 'string'
            ? undefined
            : (formData.logo as File),
        street: formData.street,
        city: formData.city,
        zip: formData.zip,
        state: formData.state,
        country: formData.country,
        username:
          `${formData.username}@${formData.username_suffix}`.toLowerCase(),
        first_name: formData.first_name,
        last_name: formData.last_name,
        phone_number:
          parsePhoneNumber(formData.phone_number, countryCode)?.format(
            'E.164',
          ) || null,
        owner_phone_number:
          parsePhoneNumber(
            formData.owner_phone_number,
            ownerCountryCode,
          )?.format('E.164') || null,
        password: formData.password,
        password_confirmation: formData.password_confirmation,
      });
      closeModal();
      setFormData(initialFormData);
      toast.success(t('partner.create-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        toast.error(error.message);
      }
      toast.error(t('partner.create-error-msg'));
    }
  };

  return (
    <Tabs
      defaultValue="details"
      className="flex flex-col flex-1 gap-6 overflow-hidden"
    >
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-4 flex-1 overflow-hidden"
      >
        <div className="flex-1 overflow-y-auto pr-3">
          <TabsList className="w-full">
            <TabsTrigger value="details" className="flex-1 w-full">
              {t('common.details')}
            </TabsTrigger>
            <TabsTrigger value="owner" className="flex-1 w-full">
              {t('partner.owner')}
            </TabsTrigger>
          </TabsList>
          <TabsContent value="details" className="w-full">
            <PartnerDetails
              formData={formData}
              setFormData={setFormData}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
            />
          </TabsContent>
          <TabsContent value="owner" className="w-full">
            <PartnerOwner
              formData={formData}
              setFormData={setFormData}
              ownerCountryCode={ownerCountryCode}
              setOwnerCountryCode={setOwnerCountryCode}
            />
          </TabsContent>
        </div>
        <DialogFooter className="self-end">
          <Button
            type="submit"
            disabled={!isValid}
            isLoading={createRestaurantPartner.isPending}
          >
            {t('partner.create')}
          </Button>
        </DialogFooter>
      </form>
    </Tabs>
  );
};
