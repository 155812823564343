import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { COMPANY_USER_ROLE, useAuthUserCompany } from '@durma-soft/gros-sdk';

import { Modal } from '@/components/shared/modal';
import { Alert } from '@/components/shared/alert';
import { Button } from '@/components/shared/shadcn-ui/button';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { EditCompanyLocationForm } from '@/components/company/locations/edit-company-location-form';

export const EditCompanyLocationModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { locationId } = useParams();

  if (!locationId) {
    throw new Error('Location ID is required for editing location');
  }

  const company = useAuthUserCompany();
  const hasEditPermission =
    company.role === COMPANY_USER_ROLE.MANAGER ||
    company.role === COMPANY_USER_ROLE.OWNER;

  const closeModal = () => navigate(`/company/locations`, { replace: true });

  const isModalOpen = Boolean(
    useMatch({
      path: '/company/locations/edit/:locationId',
    }),
  );

  return (
    <Modal
      key={locationId}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('company-locations.edit')}
      description={t('company-locations.edit-details')}
      className="overflow-hidden flex flex-col"
    >
      {!hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.edit-not-allowed')}
          description={t('common.edit-not-allowed-description')}
        />
      )}

      {hasEditPermission && (
        <ErrorBoundary
          fallbackRender={({ resetErrorBoundary }) => {
            return (
              <div className="flex flex-col gap-3">
                <Alert
                  variant="destructive"
                  title={t('common.error')}
                  description={t('common.error-description')}
                />
                <Button onClick={resetErrorBoundary}>
                  {t('common.try-again')}
                </Button>
              </div>
            );
          }}
        >
          <Suspense fallback={<ModalFormSkeleton numberOfSkeletons={10} />}>
            <EditCompanyLocationForm
              closeModal={closeModal}
              locationId={locationId}
            />
          </Suspense>
        </ErrorBoundary>
      )}
    </Modal>
  );
};
