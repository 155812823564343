import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { CountryCode } from 'libphonenumber-js';
import { useState, useId, FormEvent, useMemo } from 'react';
import { RestaurantCompany } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  ValidationError,
  useEditRestaurantCompanyMutation,
  asYouTypePhoneNumber,
  parsePhoneNumber,
  phoneNumberInputPlaceholder,
  useAuthUserRestaurant,
} from '@durma-soft/gros-sdk';

import { Input } from '@/components/shared/shadcn-ui/input';
import { Label } from '@/components/shared/shadcn-ui/label';
import { InputAddon } from '@/components/shared/input-addon';
import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import { CustomFileInput } from '@/components/shared/custom-file-input';
import { SelectCountryCallingCode } from '@/components/shared/select-country-code';

import { useChangedObject } from '@/hooks/use-changed-object';

interface EditRestaurantCompanyForm {
  company: RestaurantCompany;
  closeModal: () => unknown;
}

interface FormState {
  name: string;
  phone_number: string;
  username_suffix: string;
  logo: File | null | string;
}

export const EditRestaurantCompanyForm = ({
  company,
  closeModal,
}: EditRestaurantCompanyForm) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormState>({
    name: company.name,
    logo: company.logo,
    phone_number:
      parsePhoneNumber(company.phone_number)?.formatNational() ||
      company.phone_number ||
      '',
    username_suffix: company.username_suffix,
  });

  const [countryCode, setCountryCode] = useState<CountryCode>(
    parsePhoneNumber(company.phone_number)?.country ||
      import.meta.env.VITE_DEFAULT_COUNTRY_CODE ||
      'BA',
  );

  const uid = useId();
  const restaurant = useAuthUserRestaurant();
  const editRestaurantCompany = useEditRestaurantCompanyMutation();

  const [isChanged, changedObj] = useChangedObject(formData, company, {
    customComparators: {
      phone_number: (a, b) => {
        if (Boolean(a) && b === null) return false;
        return parsePhoneNumber(a as string, countryCode)?.format('E.164') == b;
      },
    },
  });

  const isValid = useMemo(() => {
    return formData.name.length > 0 && isChanged;
  }, [formData, isChanged]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isChanged) return;
    try {
      await editRestaurantCompany.mutateAsync({
        restaurant_id: restaurant.id,
        company_id: company.id,
        ...changedObj,
        logo: typeof formData.logo === 'string' ? undefined : formData.logo,
        phone_number: changedObj.phone_number
          ? parsePhoneNumber(formData.phone_number, countryCode)?.format(
              'E.164',
            ) || ''
          : undefined,
      });
      closeModal();
      toast.success(t('company.edit-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('company.edit-error-msg'));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid gap-4 py-4">
        {/* Name */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-name'}>{t('common.name')}</Label>
          <div className="flex items-stretch w-full">
            <Input
              required
              id={uid + '-name'}
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              placeholder={t('common.name-placeholder')}
            />
          </div>
        </div>
        {/* Username suffix */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-username-suffix'}>
            {t('company.suffix')}
          </Label>
          <div className="flex items-stretch">
            <InputAddon position="left">@</InputAddon>
            <Input
              className="flex-1 border-l-0 rounded-tl-none rounded-bl-none"
              id={uid + '-username-suffix'}
              value={formData.username_suffix}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  username_suffix: e.target.value,
                })
              }
              placeholder={t('company.suffix-placeholder')}
            />
          </div>
        </div>
        {/* Phone number */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-phone-number'}>
            {t('company.phone-number')}
          </Label>
          <div className="flex items-stretch">
            <InputAddon position="left">
              <SelectCountryCallingCode
                value={countryCode}
                onChange={(e) => {
                  setCountryCode(e.target.value as CountryCode);
                  setFormData({
                    ...formData,
                    phone_number: '',
                  });
                }}
              />
            </InputAddon>
            <Input
              className="flex-1 border-l-0 rounded-tl-none rounded-bl-none"
              id={uid + '-phone-number'}
              value={formData.phone_number}
              placeholder={phoneNumberInputPlaceholder[countryCode]}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  phone_number: asYouTypePhoneNumber(
                    e.target.value,
                    countryCode,
                  ),
                })
              }
            />
          </div>
        </div>
        {/* Logo */}
        <div className="flex flex-col gap-2">
          <CustomFileInput
            label={t('common.add-logo')}
            hasDeleteButton={Boolean(formData.logo)}
            onDelete={() => setFormData({ ...formData, logo: null })}
            handleFile={(file) => {
              setFormData({ ...formData, logo: file });
            }}
          />
          <div>
            {formData.logo && (
              <img
                src={
                  typeof formData.logo === 'string'
                    ? formData.logo
                    : URL.createObjectURL(formData.logo)
                }
                height="128"
                width="128"
                className="object-contain object-center w-32 h-32 rounded-md"
              />
            )}
          </div>
        </div>
      </div>
      <DialogFooter className="mt-2">
        <Button
          type="submit"
          disabled={!isValid}
          isLoading={editRestaurantCompany.isPending}
        >
          {t('common.save-edit')}
        </Button>
      </DialogFooter>
    </form>
  );
};
