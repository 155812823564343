import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Check, ChevronsUpDown } from 'lucide-react';
import { ShortCompanyLocation } from '@durma-soft/gros-sdk/dist/types/company';

import { Spinner } from '@/components/shared/spinner';
import { Button } from '@/components/shared/shadcn-ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/shared/shadcn-ui/popover';
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/shared/shadcn-ui/command';

import { cn } from '@/utils/helpers';

interface SelectSingleCompanyLocationsProps {
  locations: ShortCompanyLocation[];
  isLoading?: boolean;
  selectedLocations: ShortCompanyLocation[];
  onUpdateLocations: (locations: ShortCompanyLocation[]) => void;
}

export const SelectSingleCompanyLocations = ({
  locations = [],
  isLoading = false,
  selectedLocations,
  onUpdateLocations,
}: SelectSingleCompanyLocationsProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  if (isLoading) return <Spinner />;

  const toggleLocations = (location: ShortCompanyLocation) => {
    if (selectedLocations.some((i) => i.id === location.id)) {
      onUpdateLocations(selectedLocations.filter((i) => i.id !== location.id));
    } else {
      onUpdateLocations([...selectedLocations, { ...location }]);
    }
  };

  return (
    <Popover modal open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
          {selectedLocations.length > 0
            ? `${selectedLocations.length} ${t('common.chosen')}`
            : t('company.choose-branches')}
          <ChevronsUpDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[320px] p-0">
        <Command>
          <CommandInput placeholder={t('common.search-placeholder')} />
          <CommandEmpty>{t('common.no-results')}</CommandEmpty>
          <CommandList>
            {locations &&
              locations.map((location) => (
                <CommandItem
                  onSelect={() => {
                    toggleLocations(location);
                  }}
                  key={location.id}
                  value={String(location.name) + String(location.id)}
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      selectedLocations.some((i) => i.id === location.id)
                        ? 'opacity-100'
                        : 'opacity-0',
                    )}
                  />
                  {location.name}
                </CommandItem>
              ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
