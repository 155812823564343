import { useTranslation } from 'react-i18next';

import { Alert } from '@/components/shared/alert';

export const UnknownTrackError = () => {
  const { t } = useTranslation();
  return (
    <Alert
      variant="destructive"
      title={t('track.errors.unknown.heading')}
      description={t('track.errors.unknown.description')}
      className="xl:[&>h5]:text-4xl xl:[&>div]:text-2xl xl:max-w-3xl xl:[&>svg]:w-10 xl:[&>svg]:h-10 xl:[&>svg~*]:pl-14"
    />
  );
};
