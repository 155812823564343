import { useInterval } from 'react-use';
import { useMemo, useState } from 'react';
import { CompanyUser } from '@durma-soft/gros-sdk/dist/types/company';
import { ShortRestaurantFoodWithQuantity } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { Card } from '@/components/shared/shadcn-ui/card';

interface TrackSuccessProps {
  user: Pick<CompanyUser, 'first_name' | 'last_name' | 'id' | 'username'>;
  foods: ShortRestaurantFoodWithQuantity[];
  isPaused: boolean;
  pausedTimestamp: number | null;
  pausedSeconds?: number;
}

export const TrackSuccess = ({
  user,
  foods,
  isPaused,
  pausedTimestamp,
  pausedSeconds: pausedSecondsProp = 5,
}: TrackSuccessProps) => {
  const [pausedSeconds] = useState(pausedSecondsProp);
  const [currentDate, setCurentDate] = useState(Date.now());

  useInterval(() => {
    setCurentDate(Date.now());
  }, 100);

  const timer = useMemo(() => {
    if (!pausedTimestamp) return null;
    return pausedSeconds - Math.round((currentDate - pausedTimestamp) / 1000);
  }, [currentDate, pausedTimestamp, pausedSeconds]);

  return (
    <Card className="p-5 relative">
      {isPaused && (
        <div className="absolute top-4 right-4 z-10 text-5xl font-semibold tabular-nums select-none text-primary">
          {timer}
        </div>
      )}
      <p className="font-medium text-xl mb-4 xl:text-4xl xl:mb-6">
        {user.first_name} {user.last_name}
      </p>
      <ul className="space-y-2.5 xl:space-y-4">
        {foods.map((food) => (
          <li key={food.id}>
            <span className="font-semibold text-3xl xl:text-5xl text-primary">
              {food.quantity}x
            </span>{' '}
            <span className="font-semibold text-3xl xl:text-5xl">
              {food.name}
            </span>
          </li>
        ))}
      </ul>
    </Card>
  );
};
