/* eslint-disable */
import { toast } from 'sonner';
import { useState } from 'react';
import QRCode from 'react-qr-code';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useParams } from 'react-router-dom';
import { Pencil, Trash2, QrCode as QrCodeIcon } from 'lucide-react';
import { CompanyEmployee } from '@durma-soft/gros-sdk/dist/types/company';
import {
  useAuthUser,
  useAuthUserCompany,
  useDeleteCompanyEmployeeMutation,
  useEditCompanyEmployeeMutation,
  useRegenerateCompanyEmployeeQrCodeMutation,
  ValidationError,
} from '@durma-soft/gros-sdk';

import { Badge } from '@/components/shared/shadcn-ui/badge';
import { Button } from '@/components/shared/shadcn-ui/button';
import { Switch } from '@/components/shared/shadcn-ui/switch';
import { ConfirmationModal } from '@/components/shared/confirmation-modal';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/shared/shadcn-ui/tooltip';

import { COMPANY_EMPLOYEE_ROLE } from '@/config/global/roles';

import print from '@/lib/print/print';
import { TType } from '@/types/general';

export const columns = (t: TType): ColumnDef<CompanyEmployee>[] => {
  return [
    {
      accessorKey: 'ordinal_number',
      header: t('common.order-number'),
      cell: (cellData) => {
        const page = cellData.table.getState().pagination;
        const rowIndex = cellData.row.index;
        const ordinalNumber = page.pageIndex * page.pageSize + rowIndex + 1;

        return <>{ordinalNumber}</>;
      },
    },
    {
      accessorKey: 'id',
      header: t('common.id'),
    },
    {
      accessorKey: 'first_name',
      header: t('common.first-name'),
    },
    {
      accessorKey: 'last_name',
      header: t('common.last-name'),
    },
    {
      accessorKey: 'username',
      header: t('common.username'),
    },
    {
      accessorKey: 'role',
      header: t('roles.title'),
      cell: (cellData) => {
        const { role } = cellData.row.original;
        return <>{COMPANY_EMPLOYEE_ROLE(t)[role]}</>;
      },
    },
    {
      accessorKey: 'sex',
      header: t('common.sex'),
      cell: (cellData) => {
        const { sex } = cellData.row.original;

        if (sex === 'male')
          return (
            <Badge className="bg-blue-500 hover:bg-blue-500">
              {t('common.male')}
            </Badge>
          );

        if (sex === 'female')
          return (
            <Badge className="bg-rose-500 hover:bg-rose-500">
              {t('common.female')}
            </Badge>
          );

        return null;
      },
    },
    {
      accessorKey: 'is_active',
      header: t('employee.is-active'),
      cell: (cellData) => {
        const { user } = useAuthUser();
        const company = useAuthUserCompany();

        const editCompanyEmployee = useEditCompanyEmployeeMutation();
        const handleActiveStatusChange = async (checked: boolean) => {
          try {
            await editCompanyEmployee.mutateAsync({
              company_id: company.id,
              employee_id: cellData.row.original.id,
              is_active: checked,
            });
            toast.success(t('employee.edit-status-success-msg'));
          } catch (error) {
            if (error instanceof ValidationError) {
              return toast.error(error.message);
            }
            toast.error(t('employee.edit-edit-error-msg'));
          }
        };

        return (
          <Switch
            disabled={cellData.row.original.id === user.id}
            checked={cellData.row.original.is_active}
            onCheckedChange={handleActiveStatusChange}
          />
        );
      },
    },
    {
      accessorKey: 'edit',
      header: '',
      cell: (cellData) => {
        const { locationId } = useParams();
        const company = useAuthUserCompany();
        const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
          useState(false);

        const { isPending, mutateAsync } = useDeleteCompanyEmployeeMutation();

        const regenerateEmployeeQRCode =
          useRegenerateCompanyEmployeeQrCodeMutation();

        const deleteCompanyEmployee = async () => {
          try {
            await mutateAsync({
              company_id: company.id,
              employee_id: cellData.row.original.id,
            });
            toast.success(t('employee.delete-success-msg'));
          } catch (error) {
            toast.error(t('employee.delete-error-msg'));
          } finally {
            setIsConfirmationModalOpen(false);
          }
        };

        const handleQRCode = async () => {
          let qrCode = cellData.row.original.qr_code_token;

          if (!qrCode) {
            try {
              qrCode = await regenerateEmployeeQRCode.mutateAsync({
                company_id: company.id,
                employee_id: cellData.row.original.id,
              });
            } catch (error) {
              return toast.error(t('employee.qr-code-generate-error'));
            }
          }

          print({
            content: (
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                <QRCode
                  style={{ margin: '0 auto' }}
                  size={256}
                  value={qrCode}
                />
                <h4 style={{ textAlign: 'center' }}>
                  {cellData.row.original.first_name}{' '}
                  {cellData.row.original.last_name} (#{cellData.row.original.id}
                  )
                </h4>
              </div>
            ),
          });
        };
        return (
          <>
            <TooltipProvider delayDuration={100}>
              <div className="flex items-center justify-center gap-5">
                {/* QR Code */}
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="ghost"
                      onClick={handleQRCode}
                      className="flex items-center justify-center text-gray-700 p-0"
                    >
                      <QrCodeIcon className="w-5 h-5" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{t('employee.qr-code')}</TooltipContent>
                </Tooltip>
                {/* Edit */}
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Link
                      replace
                      to={
                        locationId
                          ? `/company/locations/${locationId}/employees/edit/${cellData.row.original.id}`
                          : `/company/employees/edit/${cellData.row.original.id}`
                      }
                      className="flex items-center justify-center text-gray-700 "
                    >
                      <Pencil className="w-5 h-5" />
                    </Link>
                  </TooltipTrigger>
                  <TooltipContent>{t('employee.edit-details')}</TooltipContent>
                </Tooltip>
                {/* Delete */}
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="ghost"
                      className="flex items-center justify-center text-gray-700 p-0"
                      onClick={() => setIsConfirmationModalOpen(true)}
                    >
                      <Trash2 className="w-5 h-5" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{t('common.delete')}</TooltipContent>
                </Tooltip>
              </div>
            </TooltipProvider>
            <ConfirmationModal
              isLoading={isPending}
              isModalOpen={isConfirmationModalOpen}
              confirmationText={t('common.delete')}
              title={t('employee.delete')}
              description={t('employee.delete-description', {
                employee: (
                  cellData.row.original.first_name +
                  cellData.row.original.last_name
                ).toLowerCase(),
              })}
              onConfirm={deleteCompanyEmployee}
              onCancel={() => setIsConfirmationModalOpen(false)}
              closeModal={() => setIsConfirmationModalOpen(false)}
            />
          </>
        );
      },
    },
  ];
};

export const printColumns = (t: TType): ColumnDef<CompanyEmployee>[] => {
  return [
    {
      accessorKey: 'ordinal_number',
      header: t('common.order-number'),
      cell: (cellData) => {
        const page = cellData.table.getState().pagination;
        const rowIndex = cellData.row.index;
        const ordinalNumber = page.pageIndex * page.pageSize + rowIndex + 1;

        return <>{ordinalNumber}</>;
      },
    },
    {
      accessorKey: 'id',
      header: t('common.id'),
    },
    {
      accessorKey: 'first_name',
      header: t('common.first-name'),
    },
    {
      accessorKey: 'last_name',
      header: t('common.last-name'),
    },
    {
      accessorKey: 'username',
      header: t('common.username'),
    },
    {
      accessorKey: 'role',
      header: t('roles.title'),
      cell: (cellData) => {
        const { role } = cellData.row.original;
        return <>{COMPANY_EMPLOYEE_ROLE(t)[role]}</>;
      },
    },
    {
      accessorKey: 'sex',
      header: t('common.sex'),
      cell: (cellData) => {
        const { sex } = cellData.row.original;

        if (sex === 'male')
          return (
            <Badge className="bg-blue-500 hover:bg-blue-500">
              {t('common.male')}
            </Badge>
          );

        if (sex === 'female')
          return (
            <Badge className="bg-rose-500 hover:bg-rose-500">
              {t('common.female')}
            </Badge>
          );

        return null;
      },
    },
  ];
};
