import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
  useGetFoodsQuery,
} from '@durma-soft/gros-sdk';

import { Link } from '@/components/shared/link';
import { DataTable } from '@/components/shared/data-table';
import { SearchInput } from '@/components/shared/search-input';
import { buttonVariants } from '@/components/shared/shadcn-ui/button';
import { EditRestaurantFoodModal } from '@/components/restaurant/foods/edit-restaurant-food-modal';
import { CreateRestaurantFoodModal } from '@/components/restaurant/foods/create-restaurant-food-modal';

import { columns } from '@/config/restaurant/foods';
import { useDebouncedSearch } from '@/hooks/use-debounced-search';

const RestaurantFoods = () => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const { foodId } = useParams();
  const memoizedColumns = useMemo(() => {
    return columns(t);
  }, [t]);

  const restaurant = useAuthUserRestaurant();
  const hasEditPermission = restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;

  const { searchInput, debouncedSearchInput, handleChange } =
    useDebouncedSearch();

  const { data, isLoading } = useGetFoodsQuery({
    restaurant_id: restaurant.id,
    q: debouncedSearchInput,
    page: pagination.pageIndex + 1,
    page_size: pagination.pageSize,
  });

  return (
    <>
      <Helmet>
        <title>{t('food.title')}</title>
      </Helmet>
      <div>
        <div className="flex items-center">
          <h1 className="text-lg font-semibold md:text-2xl">
            {t('food.title')}
          </h1>
        </div>
        <div className="flex flex-col gap-3">
          <div className="self-end">
            {restaurant.role === RESTAURANT_USER_ROLE.OWNER && (
              <Link
                to="/restaurant/foods/create"
                className={buttonVariants({ variant: 'default' })}
              >
                {t('food.create')}
              </Link>
            )}
          </div>
          <div className="flex flex-col gap-3">
            <SearchInput
              className="self-start p-0"
              placeholder={t('common.search-placeholder')}
              searchInput={searchInput}
              handleChange={(event) => {
                handleChange(event);
                setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
              }}
            />
            <DataTable
              columns={memoizedColumns}
              data={data?.data}
              pageCount={data?.total_pages}
              isLoading={isLoading}
              pagination={pagination}
              setPagination={setPagination}
              columnVisibility={{
                edit: hasEditPermission,
              }}
            />
          </div>
        </div>
      </div>
      <CreateRestaurantFoodModal />
      {Boolean(foodId) && <EditRestaurantFoodModal key={foodId} />}
    </>
  );
};

export default RestaurantFoods;
