import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useAuthUserRestaurant,
  useGetRestaurantCompanyLocationsQuery,
} from '@durma-soft/gros-sdk';

import { Link } from '@/components/shared/link';
import { NavLink } from '@/components/shared/nav-link';
import { DataTable } from '@/components/shared/data-table';
import { buttonVariants } from '@/components/shared/shadcn-ui/button';
import { CreateRestaurantCompanyLocationModal } from '@/components/restaurant/companies/create-restaurant-company-location-modal';
import { EditRestaurantCompanyLocationModal } from '@/components/restaurant/companies/edit-restaurant-company-location-modal';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/shared/shadcn-ui/breadcrumb';

import { columns } from '@/config/restaurant/company-locations';

const RestaurantCompanyLocations = () => {
  const { t } = useTranslation();

  const restaurant = useAuthUserRestaurant();
  const { companyId, locationId } = useParams();

  if (!companyId) {
    throw new Error('Company ID are required getting location');
  }
  const memoizedColumns = useMemo(() => {
    return columns(t);
  }, [t]);

  const { data, isLoading } = useGetRestaurantCompanyLocationsQuery({
    restaurant_id: restaurant.id,
    company_id: Number(companyId),
  });

  return (
    <>
      <Helmet>
        <title>{t('company-locations.title')}</title>
      </Helmet>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <NavLink to={`/restaurant/companies`}>{t('company.title')}</NavLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <NavLink
              to={`/restaurant/companies/${companyId}/locations`}
              className={({ isActive }) => (isActive ? 'text-primary' : '')}
            >
              {t('company-locations.title')}
            </NavLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <div>
        <div className="flex items-center">
          <h1 className="text-lg font-semibold md:text-2xl">
            {t('company-locations.title')}
          </h1>
        </div>
        <div className="flex flex-col gap-3">
          <div className="self-end">
            <Link
              to={`/restaurant/companies/${companyId}/locations/create`}
              className={buttonVariants({ variant: 'default' })}
            >
              {t('company-locations.create')}
            </Link>
          </div>
          <DataTable
            columns={memoizedColumns}
            data={data}
            isLoading={isLoading}
          />
        </div>
      </div>
      <CreateRestaurantCompanyLocationModal />
      {Boolean(locationId) && (
        <EditRestaurantCompanyLocationModal key={locationId + companyId} />
      )}
    </>
  );
};

export default RestaurantCompanyLocations;
