import './utils/sentry';

import { Toaster } from 'sonner';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import {
  QueryClientProvider,
  AuthProvider,
  updateBaseURL,
  updateToken,
} from '@durma-soft/gros-sdk';

import '@/style/global.css';
import '@/localization/i18n';

import { LOCAL_STORAGE_TOKEN_KEY } from '@/config/global/storage';

import App from '@/App.tsx';

import SidebarToggleProvider from '@/providers/SidebarToggleProvider';

import { AuthErrorMiddleware } from '@/middleware/auth-error-middleware';
import { UserLocaleMiddleware } from '@/middleware/user-locale-middleware';
import { AuthLoadingMiddleware } from '@/middleware/auth-loading-middleware';

import i18n from '@/localization/i18n';

updateBaseURL(import.meta.env.VITE_API_URL || '');
updateToken(localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || '');

createRoot(document.getElementById('root')!).render(
  <I18nextProvider i18n={i18n}>
    <QueryClientProvider>
      <BrowserRouter future={{ v7_startTransition: true }}>
        <AuthProvider>
          <AuthLoadingMiddleware>
            <AuthErrorMiddleware>
              <UserLocaleMiddleware>
                <HelmetProvider>
                  <SidebarToggleProvider>
                    <App />
                  </SidebarToggleProvider>
                </HelmetProvider>
              </UserLocaleMiddleware>
            </AuthErrorMiddleware>
          </AuthLoadingMiddleware>
        </AuthProvider>
        <Toaster richColors />
      </BrowserRouter>
    </QueryClientProvider>
  </I18nextProvider>,
);
