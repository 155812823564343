import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { useMatch, useNavigate } from 'react-router-dom';

import { Alert } from '@/components/shared/alert';
import { Modal } from '@/components/shared/modal';
import { Button } from '@/components/shared/shadcn-ui/button';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { CreateCompanyLocationForm } from '@/components/company/locations/create-company-location-form';

export const CreateCompanyLocationModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const closeModal = () => {
    navigate(`/company/locations`, { replace: true });
  };

  const isModalOpen = Boolean(useMatch({ path: '/company/locations/create' }));

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('company-locations.create')}
      description={t('company-locations.create-details')}
      className="overflow-hidden flex flex-col"
    >
      <ErrorBoundary
        fallbackRender={({ resetErrorBoundary }) => {
          return (
            <div className="flex flex-col gap-3">
              <Alert
                variant="destructive"
                title={t('common.error')}
                description={t('common.error-description')}
              />
              <Button onClick={resetErrorBoundary}>
                {t('common.try-again')}
              </Button>
            </div>
          );
        }}
      >
        <Suspense fallback={<ModalFormSkeleton numberOfSkeletons={10} />}>
          <CreateCompanyLocationForm closeModal={closeModal} />
        </Suspense>
      </ErrorBoundary>
    </Modal>
  );
};
