import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthUser } from '@durma-soft/gros-sdk';

interface UserCanAccessRestaurantMiddlewareProps {
  children?: ReactNode;
}

export const UserCanAccessRestaurantMiddleware = ({
  children,
}: UserCanAccessRestaurantMiddlewareProps) => {
  const { restaurant } = useAuthUser();

  if (!restaurant) {
    return <Navigate to="/" />;
  }

  if (!restaurant.is_active) {
    return <Navigate to="/not-allowed" />;
  }

  return <>{children}</>;
};
