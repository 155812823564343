import { useMemo } from 'react';
import { format, isEqual } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  useAuthUserCompany,
  useGetCompanyFoodReportQuery,
} from '@durma-soft/gros-sdk';

import { useDatePickerRangeState } from '@/hooks/use-date-picker-range-state';

import { PrintButton } from '@/components/shared/print-button';
import { DataTable, DataTableHTML } from '@/components/shared/data-table';
import { DatePickerWithRange } from '@/components/shared/date-picker-with-range';

import print from '@/lib/print/print';

import { columns } from '@/config/company/reports/company-foods';

const CompanyFoodsReport = () => {
  const { t } = useTranslation();
  const memoizedColumns = useMemo(() => columns(t), [t]);

  const { from, to, setDateRange } = useDatePickerRangeState();

  const company = useAuthUserCompany();

  const { data, isLoading } = useGetCompanyFoodReportQuery({
    company_id: company.id,
    start_date: from,
    end_date: to,
  });

  return (
    <>
      <Helmet>
        <title>{t('reports.foods.title')}</title>
      </Helmet>
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl">
          {t('reports.foods.title')}
        </h1>
      </div>
      <div className="flex justify-between mt-5 mb-5">
        <DatePickerWithRange
          date={{ from: new Date(from), to: new Date(to) }}
          setDate={([from, to]) => setDateRange({ from, to })}
        />
        <PrintButton
          onClick={() =>
            print({
              content: (
                <div>
                  <div>
                    <h1>{t('reports.foods.title')} </h1>
                    <p>
                      {format(from, 'dd.MM.yyyy')}
                      {!isEqual(from, to) && ` - ${format(to, 'dd.MM.yyyy')}`}
                    </p>
                  </div>
                  <DataTableHTML columns={memoizedColumns} data={data} />
                </div>
              ),
            })
          }
        />
      </div>
      <div className="flex flex-col gap-3">
        <DataTable
          columns={memoizedColumns}
          data={data}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default CompanyFoodsReport;
