import { useTranslation } from 'react-i18next';
import {
  Locale,
  useAuthUser,
  useUpdateCurrentUserLocaleMutation,
} from '@durma-soft/gros-sdk';
import { ArrowLeftToLineIcon, ArrowRightFromLine } from 'lucide-react';

import { SelectLanguage } from '@/components/shared/select-language';

import { UserAccount } from '@/layout/header/user-account';
import { SidebarMobile } from '@/layout/sidebar/sidebar-mobile';
import { useSidebarToggle } from '@/providers/SidebarToggleProvider';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/shared/shadcn-ui/tooltip';

const Header = () => {
  const { t, i18n } = useTranslation();
  const { user } = useAuthUser();
  const [sidebarOpen, setSidebarOpen] = useSidebarToggle();
  const updateUserLocaleMutation = useUpdateCurrentUserLocaleMutation();

  return (
    <header className="flex h-14 items-center gap-4 border-b bg-muted/40 px-4 lg:h-15 lg:px-6 print:hidden">
      <SidebarMobile />
      <div className="hidden md:h-full flex-1 md:flex md:justify-between md:items-center">
        <div className="flex items-center gap-3">
          <TooltipProvider delayDuration={100}>
            <Tooltip>
              <TooltipTrigger asChild>
                <button
                  type="button"
                  className="inline-flex [&>svg]:size-5 [&>svg]:cursor-pointer items-center justify-center rounded-md p-2 text-black/75 hover:bg-gray-100 active:bg-gray-200 transition"
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                  {sidebarOpen ? (
                    <ArrowLeftToLineIcon />
                  ) : (
                    <ArrowRightFromLine />
                  )}
                </button>
              </TooltipTrigger>
              <TooltipContent>
                {sidebarOpen
                  ? t('sidebar.hide-sidebar')
                  : t('sidebar.show-sidebar')}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <div className="h-full flex flex-col justify-center">
            <span className="font-semibold leading-none tracking-tight">
              {user.first_name + ' ' + user.last_name}
            </span>
            <span className="text-sm text-muted-foreground tracking-tight">
              {user.username}
            </span>
          </div>
        </div>
        <div>
          <div
            id="header-desktop-nav-items"
            className="flex items-center gap-2"
          >
            <div>
              <SelectLanguage
                value={user.locale}
                isLoading={updateUserLocaleMutation.isPending}
                placeholder={t(`languages.${user.locale as Locale}`)}
                onValueChange={(lang) => {
                  i18n.changeLanguage(lang);
                  updateUserLocaleMutation.mutate({ locale: lang as Locale });
                }}
              />
            </div>
            <div>
              <UserAccount />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
